import { DEVICE_KEY_NINJA_ONE } from '@totem/components/devices/software/utilities';
import { DeviceQueryResult } from '@totem/types/devices';
import { isNotNull } from '@totem/utilities/common';

export const FormatBytes = (bytes: number, decimals: number = 2) => {
  if (!+bytes) {
    return '0 Bytes';
  }

  const kb = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Hz', 'KHz', 'MHz', 'GHz', 'THz', 'PHz', 'EHz', 'ZHz', 'YHz'];

  const index: number = Math.floor(Math.log(bytes) / Math.log(kb));

  return `${parseFloat((bytes / Math.pow(kb, index)).toFixed(dm))} ${
    sizes[index]
  }`;
};

export const hasProcessors = (device: DeviceQueryResult) => {
  if (
    isNotNull(device) &&
    isNotNull(device.device) &&
    isNotNull(device.device.keys)
  ) {
    for (let idx = 0; idx < device.device.keys.length; idx++) {
      const chkKey = device.device.keys[idx];
      if (chkKey.type === DEVICE_KEY_NINJA_ONE && chkKey.createdBy !== "PERMANENT DELETE") {
        return true;
      }
    }
  }

  return false;
};
