import { createContext } from 'react';

import {
  EventFilterInput,
  EventFilterOptions,
  EventFilters,
} from '@totem/components/events/eventFilters/types';

interface Context {
  input: EventFilterInput;
  setInput: (input: EventFilterInput) => void;
  filterOptions: EventFilterOptions;
  staticFilters: EventFilters;
  historyOnly: boolean;
}

export default createContext<Context>({
  input: null,
  setInput: () => {},
  filterOptions: null,
  staticFilters: null,
  historyOnly: false,
});
