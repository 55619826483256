import React, { ReactNode, useEffect, useState } from 'react';

import TicketingContext from '@totem/components/ticketing/listContainer/ticketingContainerContext';
import {
  addFilters,
  buildFilters,
} from '@totem/components/ticketing/utilities';
import { ObjectReference, Params } from '@totem/types/common';
import {
  TicketingConnectionInput,
  TicketingFilterOptions,
  TicketingFilters,
  TicketQueryResult,
  TicketQueryResults,
} from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { TICKETS_ENDPOINT } from '@totem/utilities/endpoints';

import '../ticketing.css';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  onDataRefreshRequested?: () => void;
  onTicketSelected?: (ticketId: string, ticket: TicketQueryResult) => void;
  children?: ReactNode;
  ticketEndPoint?: string;
  defaultFilters?: TicketingFilters;
  staticFilters?: TicketingFilters;
};

// eslint-disable-next-line react/prop-types
const TicketEmbeddedContainer = ({
  onRecordTotalChanged,
  onDataRefreshRequested,
  refresh,
  children,
  ticketEndPoint,
  defaultFilters,
  staticFilters,
  onTicketSelected,
}: Props) => {
  const [input, updateInput] = useState<TicketingConnectionInput>({
    from: '',
    pageSize: 10,
    page: 1,
    sortField: 'createdAt',
    sortDirection: '1',
    ids:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.ids !== 'undefined' &&
      defaultFilters.ids !== null
        ? defaultFilters.ids
        : [],
    buildingId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.buildingId !== 'undefined' &&
      defaultFilters.buildingId !== null
        ? defaultFilters.buildingId
        : [],
    regionId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.regionId !== 'undefined' &&
      defaultFilters.regionId !== null
        ? defaultFilters.regionId
        : [],
    controlSystemId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.controlSystemId !== 'undefined' &&
      defaultFilters.controlSystemId !== null
        ? defaultFilters.controlSystemId
        : [],
    deviceId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.deviceId !== 'undefined' &&
      defaultFilters.deviceId !== null
        ? defaultFilters.deviceId
        : [],
    queueId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.queueId !== 'undefined' &&
      defaultFilters.queueId !== null
        ? defaultFilters.queueId
        : [],
    type:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.type !== 'undefined' &&
      defaultFilters.type !== null
        ? defaultFilters.type
        : [],
    status:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.status !== 'undefined' &&
      defaultFilters.status !== null
        ? defaultFilters.status
        : [],
    reference:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.reference !== 'undefined' &&
      defaultFilters.reference !== null
        ? defaultFilters.reference
        : [],
    category:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.category !== 'undefined' &&
      defaultFilters.category !== null
        ? defaultFilters.category
        : [],
    priority:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.priority !== 'undefined' &&
      defaultFilters.priority !== null
        ? defaultFilters.priority
        : [],
  });

  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [isFilterOptionsLoaded, setIsFilterOptionsLoaded] =
    useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<TicketingFilterOptions>({
    type: [],
    priority: [],
    status: [],
    subStatus: [],
    categories: [],
    building: [],
    organization: [],
    device: [],
  });
  const [ticketData, setTicketData] = useState<TicketQueryResults>(null);
  const [selectedTickets, setSelectedTickets] = useState<ObjectReference[]>([]);
  const setInput = (updated: Partial<TicketingConnectionInput>) => {
    updateInput({ ...input, ...updated });
    setRefreshData(true);
  };

  useEffect(() => {
    if (
      typeof onRecordTotalChanged !== 'undefined' &&
      onRecordTotalChanged !== null
    ) {
      onRecordTotalChanged(totalRecords);
    }
  }, [onRecordTotalChanged, totalRecords]);

  useEffect(() => {
    if (
      refresh &&
      typeof onDataRefreshRequested !== 'undefined' &&
      onDataRefreshRequested !== null
    ) {
      setRefreshData(true);
      onDataRefreshRequested();
    }
  }, [refresh, onDataRefreshRequested]);

  useEffect(() => {
    setRefreshData(true);
  }, [ticketEndPoint, staticFilters]);

  const handleSelectTicket = (ticketId: string, ticketNumber: string) => {
    const foundIdx = selectedTickets.findIndex((chk) => chk.id === ticketId);
    if (foundIdx === -1) {
      const newReference: ObjectReference = {
        id: ticketId,
        name: ticketNumber,
      };
      setSelectedTickets((prevState) => [...prevState, newReference]);
    }
  };

  const handleDeselectTicket = (ticketId: string) => {
    setSelectedTickets((prevState) =>
      prevState.filter((chk) => chk.id !== ticketId),
    );
  };

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildFilters(input),
    };

    params.filters = addFilters(params.filters, staticFilters);

    return params;
  };

  useEffect(() => {
    if (!isFilterOptionsLoaded) {
      fetch(`${TICKETS_ENDPOINT}/filterOptions`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: TicketingFilterOptions) => {
          setFilterOptions(result);
        })
        .then(() => {
          setIsFilterOptionsLoaded(true);
        });
    }
  }, [isFilterOptionsLoaded]);

  useEffect(() => {
    if (refreshData) {
      if (isSending) {
        return;
      }
      setIsLoading(true);
      const params: Params = buildParameters();

      const ticketUrl =
        typeof ticketEndPoint !== 'undefined' && ticketEndPoint !== null
          ? ticketEndPoint
          : TICKETS_ENDPOINT;

      fetch(`${ticketUrl}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: TicketQueryResults) => {
          setTicketData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsLoading(false);
          setIsSending(false);
        });

      setIsSending(true);
      setRefreshData(false);
    }
  }, [refreshData, refresh]);

  return (
    <TicketingContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        ticketData,
        staticFilters,
        filterOptions,
        totalRecords,
        onTicketSelected,
        selectedTickets,
        clearSelectedTickets: () => setSelectedTickets([]),
        selectTicket: handleSelectTicket,
        deselectTicket: handleDeselectTicket,
        refetch: () => {
          setRefreshData(true);
        },
      }}
    >
      <div>{children}</div>
    </TicketingContext.Provider>
  );
};

export default TicketEmbeddedContainer;
