import React, { useContext } from 'react';

import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';
import RoleOverlayContainer from '@totem/components/remoteAccess/temperedOverlays/roleOverlayContainer';
import GraphWrapper from '@totem/components/remoteAccess/roleUsers/byRole/GraphWrapper';

const RoleDataAccessWrapper = () => {
  const { data } = useContext(RemoteAccessUserContext);

  const getRoleName = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data.orgRole;
    }
    return '';
  };

  return (
    <RoleOverlayContainer roleName={getRoleName()}>
      <GraphWrapper />
    </RoleOverlayContainer>
  );
};

export default RoleDataAccessWrapper;
