import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';

import { EligibleSecurityGroup, User } from '@totem/types/user';
const { Option } = Select;

const FormItem = Form.Item;

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { USERS_ELIGIBLE_GROUPS_ENDPOINT } from '@totem/utilities/endpoints';
import {
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

export interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: (securityGroup: EligibleSecurityGroup) => void;
}
const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

const SecurityGroupSelectorDialog = ({ visible, onClose, onSubmit }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [isGroupsLoading, setIsGroupsLoading] = useState<boolean>(true);
  const [eligibleGroups, setEligibleGroups] = useState<EligibleSecurityGroup[]>(
    [],
  );
  const [securityGroups, setSecurityGroups] = useState<EligibleSecurityGroup[]>(
    [],
  );

  const [selectedGroup, setSelectedGroup] = useState<string>('');

  useEffect(() => {
    const hasAssignablePermission = (grp: EligibleSecurityGroup) => {
      if (grp.assignerRole <= userProfile.organization.role) {
        if (
          isMemberOfAny(
            userProfile,
            grp.assignerSecurityGroups,
            securityGroupCheckConstraintEmpty,
          )
        ) {
          return true;
        }
      }

      return false;
    };

    fetch(USERS_ELIGIBLE_GROUPS_ENDPOINT, {
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsGroupsLoading(false);

        const filteredGroups: EligibleSecurityGroup[] = [];

        for (let idx = 0; idx < result.length; idx++) {
          const checkGroup: EligibleSecurityGroup = {
            id: result[idx].id,
            name: result[idx].name,
            description: result[idx].description,
            displayName: result[idx].displayName,
            serviceId: result[idx].serviceId,
            assignableLevels: result[idx].assignableLevels,
            assignerRole: result[idx].assignerRole,
            assignerSecurityGroups: result[idx].assignerSecurityGroups,
          };

          if (hasAssignablePermission(checkGroup)) {
            filteredGroups.push(checkGroup);
          }
        }

        setEligibleGroups(filteredGroups);
        setIsGroupsLoading(false);
      })
      .then(() => setIsGroupsLoading(false));
  }, [userProfile]);

  useEffect(() => {
    setSecurityGroups(
      eligibleGroups.sort((option1, option2) => {
        if (option1.name < option2.name) {
          return -1;
        }
        if (option1.name > option2.name) {
          return 1;
        }
        return 0;
      }),
    );
  }, [eligibleGroups]);

  const handleSecurityGroupChanged = (securityGroup: string) => {
    setSelectedGroup(securityGroup);
  };

  const handleSubmit = () => {
    const foundGroup = securityGroups.find((grp) => grp.name === selectedGroup);
    if (typeof foundGroup !== 'undefined') {
      onSubmit(foundGroup);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Add Security Group</ModalTitle>}
      okText="Add"
      confirmLoading={false}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            loading={isGroupsLoading}
            type="primary"
          >
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Security Group" colon={false} style={styles.formItem}>
          <Select
            onChange={handleSecurityGroupChanged}
            defaultValue={selectedGroup}
            style={{ width: '100%' }}
          >
            <Option key="*" value="">
              --Select--
            </Option>
            {securityGroups !== null &&
              securityGroups.map((group) => (
                <Option key={group.id} value={group.name}>
                  {group.displayName}
                </Option>
              ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default SecurityGroupSelectorDialog;
