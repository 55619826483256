import React, { ReactNode, useEffect, useState } from 'react';

import {
  DocumentsFilters,
  DocumentsInput,
} from '@totem/components/documents/filters';
import DocumentsContext from '@totem/components/documents/report/documentsContext';
const confirm = Modal.confirm;
import {
  DocumentReference,
  DocumentSummaryEntry,
  DocumentSummaryResponse,
} from '@totem/components/documents/types';
import DocumentUploadDialog from '@totem/components/documents/upload/documentUploadDialog';
import {
  addDocumentFilters,
  buildDocumentFilters,
} from '@totem/components/documents/utilities';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { DOCUMENTS_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';
import { Modal } from 'antd';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

type Props = {
  staticFilters?: DocumentsFilters;
  documentReference?: DocumentReference;
  children?: ReactNode;
};

const DocumentsContainer = ({
  children,
  staticFilters,
  documentReference,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [data, setData] = useState<DocumentSummaryEntry[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [action, setAction] = useState<string>('');
  const [actionItem, setActionItem] = useState<DocumentSummaryEntry>(null);
  const [input, updateInput] = useState<DocumentsInput>({
    page: 1,
    pageSize: 10,
    sortField: 'document.displayName',
    sortDirection: '1',
  });
  const [selectedDocuments, setSelectedDocuments] = useState<
    DocumentSummaryEntry[]
  >([]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setLoading(true);

      const params: Params = {
        paging: {
          page: input.page,
          pageSize: input.pageSize,
        },
        sort: {
          field: input.sortField,
          direction: +input.sortDirection,
        },
        filters: buildDocumentFilters(input),
      };
      params.filters = addDocumentFilters(
        params.filters,
        staticFilters,
        documentReference,
      );

      fetch(`${DOCUMENTS_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: DocumentSummaryResponse) => {
          setTotalRecords(result.paging.totalRecords);
          setData(result.results);
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [refreshData]);

  const setInput = (updated: Partial<DocumentsInput>) => {
    updateInput((prevInput) => omitNilOrEmpty({ ...prevInput, ...updated }));
    setRefreshData(true);
  };

  const setDocumentSelected = (
    docSelected: DocumentSummaryEntry,
    selected: boolean,
  ) => {
    if (selected) {
      if (
        selectedDocuments.findIndex(
          (chk) => chk.document.id === docSelected.document.id,
        ) <= -1
      ) {
        setSelectedDocuments([...selectedDocuments, docSelected]);
      }
    } else {
      const filtered = selectedDocuments.filter(
        (chk) => chk.document.id !== docSelected.document.id,
      );
      setSelectedDocuments(filtered);
    }
  };

  const handleHardDelete = (docToDelete: DocumentSummaryEntry) => {
    confirm({
      title: 'Are you sure?',
      content: `Please confirm that you would like to permanently remove the document ${docToDelete.document.displayName}.  This action cannot be undone!`,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      visible: false,
      onOk: () => {
        fetch(`${DOCUMENTS_ENDPOINT}/${docToDelete.document.id}`, {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        })
          .then((res) => {
            CheckResponseShowError(res);
          })
          .then(() => {
            handleAction('refresh', null);
          });
      },
    });    
  }
  
  const handleAction = (newAction: string, docEntry: DocumentSummaryEntry) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        setActionItem(null);
        break;
      case 'hard-delete':
        setAction('');
        setActionItem(null);
        handleHardDelete(docEntry);
        break;
      default:
        setAction(newAction);
        setActionItem(docEntry);
        break;
    }
  };

  return (
    <DocumentsContext.Provider
      value={{
        loading,
        data,
        totalRecords,
        action,
        actionItem,
        onAction: handleAction,
        input,
        setInput,
        selectedDocuments,
        setDocumentSelected,
        documentReference,
      }}
    >
      <div>{children}</div>
      {action === 'upload' && (
        <DocumentUploadDialog
          onClose={(refresh) =>
            refresh ? handleAction('refresh', null) : handleAction('', null)
          }
          visible={action === 'upload'}
        />
      )}
    </DocumentsContext.Provider>
  );
};

export default DocumentsContainer;
