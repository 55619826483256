import React, { useCallback, useEffect, useState } from 'react';
import { AutoComplete, Input } from 'antd';

import { BuildingContactAutoCompleteValue } from '@totem/types/autoComplete';
import { Filter } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull, isNull } from '@totem/utilities/common';
import { debounce } from '@totem/utilities/debounce';
import { AUTO_COMPLETE_ENDPOINT } from '@totem/utilities/endpoints';

const { Search } = Input;

const DEBOUNCE_TIME = 500;

const styles = {
  input: {
    width: '100%',
  },
};

export interface Props {
  label: string;
  type: string;
  limit: number;
  filters?: Filter[];
  selectedValue: BuildingContactAutoCompleteValue;
  style?: React.CSSProperties;
  onSelect: (selectedValue: BuildingContactAutoCompleteValue) => void;
  onChange: (value: string) => void;
  disabled?: boolean;
  allowClear?: boolean;
  allowFreeform?: boolean;
}

const BuildingContactsAutoComplete = ({
  type,
  label,
  limit,
  filters,
  selectedValue,
  style,
  onChange,
  onSelect,
  disabled,
  allowClear,
  allowFreeform,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [options, setOptions] = useState<BuildingContactAutoCompleteValue[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const handleSearch = useCallback(debounce(setSearchTerm, DEBOUNCE_TIME), []);

  const forceSelection =
    typeof allowFreeform !== 'undefined' && allowFreeform !== null
      ? !allowFreeform
      : true;

  const buildRequest = () => {
    return {
      type,
      searchTerms: searchTerm,
      limit,
      filters,
    };
  };

  useEffect(() => {
    setSearchTerm(isNotNull(selectedValue) ? selectedValue.name : '');
  }, []);

  useEffect(() => {
    if (searchTerm !== '') {
      setLoading(true);
      fetch(`${AUTO_COMPLETE_ENDPOINT}/buildingContacts`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(buildRequest()),
      })
        .then((res) => res.json())
        .then((result: BuildingContactAutoCompleteValue[]) => {
          const deDupped: BuildingContactAutoCompleteValue[] = [];
          for (let idx = 0; idx < result.length; idx++) {
            if (
              deDupped.findIndex(
                (chk) =>
                  chk.name === result[idx].name &&
                  chk.email === result[idx].email,
              ) < 0
            ) {
              deDupped.push(result[idx]);
            }
          }
          setOptions(deDupped);
        })
        .then(() => setLoading(false));
    }
  }, [searchTerm, filters]);

  const getData = () =>
    isNull(options)
      ? []
      : options.map((option) => ({
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 250,
              }}
            >
              <div>{isNotNull(option.name) ? option.name : ''}:&nbsp;&nbsp;&nbsp;</div>
              <div style={{ color: 'gray' }}>{option.email}</div>
            </div>
          ),
          value: option.name + option.email,
        }));

  const handleSelect = (_, option: any) => {
    const foundOption = options.find(
      (values) => values.name + values.email === option.value,
    );
    onSelect(foundOption);
    setSearchTerm(foundOption.name);
  };

  const onChanged = (val: string) => {
    setSearchTerm(val);
    onChange(val);
  };

  const onBlur = () => {
    if (
      forceSelection &&
      (searchTerm !== selectedValue.name || searchTerm !== selectedValue.email)
    ) {
      setSearchTerm('');
    }
  };

  return (
    <AutoComplete
      value={searchTerm}
      onChange={onChanged}
      onBlur={onBlur}
      options={getData()}
      style={{ ...styles.input, ...style }}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={label}
      disabled={disabled ?? false}
      notFoundContent={'No results found'}
      children={
        <Search allowClear={allowClear} type="search" loading={loading} />
      }
    />
  );
};

export default BuildingContactsAutoComplete;
