import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Modal, notification } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import { LookupReference } from '@totem/components/settings/sections/buildingMapping/types';
import SentinelOneContext from '@totem/components/settings/sections/sentinelOne/SentinelOneContext';
import { RequestSentinelOneTenantUpdate } from '@totem/components/settings/sections/sentinelOne/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { SENTINEL_ONE_ENDPOINT } from '@totem/utilities/endpoints';
const FormItem = Form.Item;

import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { TenantReportRecord } from '@totem/components/settings/sections/auvikMapping/types';
import { TenantData } from '@totem/components/settings/sections/sentinelOne/reportTypes';
import UserProfileContext from '@totem/components/UserProfileContext';
import { AutoCompleteValue } from '@totem/types/autoComplete';
import { isNotNull } from '@totem/utilities/common';
import { emptyId } from '@totem/utilities/userUtilities';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  indent: {
    marginLeft: '20px',
  },
};

export interface Props {
  visible: boolean;
  tenantRecord: TenantData;
}

const SentinelOneBuildingSelectModal = ({ visible, tenantRecord }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { onActionComplete } = useContext(SentinelOneContext);
  const [isSending, setIsSending] = useState(false);
  const [selectedBuildingDetails, setSelectedBuildingDetails] =
    useState<AutoCompleteValue>(null);
  const [selectedBuilding, setSelectedBuilding] = useState<LookupReference>(
    isNotNull(tenantRecord) &&
    isNotNull(tenantRecord.tenant) &&
    isNotNull(tenantRecord.building)
      ? {
        id: tenantRecord.building.id,
        name: tenantRecord.building.name,
      }
      : { id: EMPTY_ID, name: '' },
  );

  const sendBuildingUpdate = useCallback(
    async (
      orgId: string,
      tenantData: TenantReportRecord,
      building: AutoCompleteValue,
    ) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const updatedTenant = { ...tenantData.tenant };
      updatedTenant.buildingId = building.value;
      updatedTenant.regionId = building.parent.value;
      updatedTenant.organizationId = orgId;

      const payload: RequestSentinelOneTenantUpdate = {
        apiConfigID: tenantRecord.id,
        tenant: updatedTenant,
      };

      fetch(`${SENTINEL_ONE_ENDPOINT}/tenants`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status < 400) {
            onActionComplete(true);
          } else {
            notification.error({
              message: 'Error building mapping!',
              description: 'An error occurred while updating building mapping.',
              duration: 0,
            });
            onActionComplete(false);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const handleSubmit = () => {
    if (selectedBuilding.id === EMPTY_ID && selectedBuilding.name !== '') {
      // ERROR - Building Not Selected
      return;
    }

    const tenantUpdate: TenantReportRecord = {
      id: tenantRecord.apiConfigId,
      region: tenantRecord.region,
      building: tenantRecord.building,
      tenant: {
        organizationId: tenantRecord.tenant.organizationId,
        regionId: isNotNull(tenantRecord.region)
          ? tenantRecord.region.id
          : emptyId,
        buildingId: isNotNull(tenantRecord.building)
          ? tenantRecord.building.id
          : emptyId,
        tenantId: tenantRecord.tenant.tenantId,
        tenantDomainPrefix: '',
        tenantName: tenantRecord.tenant.tenantName,
        syncTimes: [],
      },
    };

    sendBuildingUpdate(
      userProfile.organization.id,
      tenantUpdate,
      selectedBuildingDetails,
    ).then(() => onActionComplete(true));
  };

  const handleBuildingChange = (building: string) => {
    const newBuilding: LookupReference = {
      id: EMPTY_ID,
      name: building,
    };

    setSelectedBuilding(newBuilding);
  };

  const handleBuildingSelect = (buildingData: AutoCompleteValue) => {
    const newBuilding: LookupReference = {
      id: buildingData.value,
      name: buildingData.display,
    };
    setSelectedBuildingDetails(buildingData);
    setSelectedBuilding(newBuilding);
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onActionComplete(false)}
      title={<ModalTitle>Map to Building</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onActionComplete(false)}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div>
        <b>Name: {tenantRecord.tenant.tenantName}</b>
      </div>
      <div>
        <b>Description: {tenantRecord.tenant.description}</b>
      </div>
      <br />
      <br />
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Building"
          colon={false}
          style={styles.formItem}
          required
        >
          <GenericAutoComplete
            type={'Building'}
            label={''}
            limit={15}
            onSelect={handleBuildingSelect}
            onChange={handleBuildingChange}
            allowClear
            allowFreeform={false}
            selectedValue={
              isNotNull(selectedBuilding)
                ? { display: selectedBuilding.name, value: selectedBuilding.id }
                : { display: '', value: '' }
            }
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default SentinelOneBuildingSelectModal;
