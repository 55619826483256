import { MaintenanceWindow, MaintenanceWindowDetails } from '@totem/components/maintenanceWindows/types';
import dayjs, { Dayjs } from 'dayjs';
import { capitalizeWords } from '@totem/utilities/stringUtilities';

export const getStatusFromDetails = (record: MaintenanceWindowDetails) => {
  const startTime = dayjs(record.scheduledStartTime);
  const endTime = dayjs(record.scheduledEndTime);
  const currentTime = dayjs();

  if (record.status === 'OPEN') {
    if (currentTime.isBefore(startTime)) {
      return 'Pending';
    }
    if (currentTime.isAfter(endTime)) {
      return 'Closed';
    }
  }

  return capitalizeWords(record.status);
};

export const getStatus = (record: MaintenanceWindow) => {
  return getStatusFromDetails(record.details);
};

export const differenceInHours = (date1: Dayjs, date2: Dayjs): number => {
  return Math.abs(date1.diff(date2, 'hour', true));
};
