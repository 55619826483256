import React, { useContext } from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import DocumentsContext from '@totem/components/documents/report/documentsContext';

const DocumentUploadButton = () => {
  const { onAction, action } = useContext(DocumentsContext);

  return (
    <Button
      disabled={action === 'upload'}
      onClick={() => {
        onAction('upload', null);
      }}
      icon={<CloudUploadOutlined />}
    >
      Upload Document
    </Button>
  );
};

export default DocumentUploadButton;
