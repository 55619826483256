import React from 'react';
import Icon from '@ant-design/icons';
import type { GetProps } from 'antd';
type CustomIconComponentProps = GetProps<typeof Icon>;
import {
  AdvancedThreatIconSvg,
  AlarmIconSvg,
  CveIconSvg,
  DataProtectIconSvg,
  ExploitIconSvg,
  HyperDetectIconSvg,
  MalwareIconSvg,
  NetworkAttackIconSvg,
  RansomwareIconSvg,
  StarOutlinedSvg,
  StarSolidSvg,
  StorageMalwareIconSvg,
} from '@totem/styles/svgIcons';

export const MalwareIcon = (props: Partial<CustomIconComponentProps>) => (
  // @ts-ignore
  <Icon component={MalwareIconSvg} {...props} />
);
export const NetworkAttackIcon = (props: Partial<CustomIconComponentProps>) => (
  // @ts-ignore
  <Icon component={NetworkAttackIconSvg} {...props} />
);
export const RansomwareIcon = (props: Partial<CustomIconComponentProps>) => (
  // @ts-ignore
  <Icon component={RansomwareIconSvg} {...props} />
);
export const StorageMalwareIcon = (
  props: Partial<CustomIconComponentProps>,
) => (
  // @ts-ignore
  <Icon component={StorageMalwareIconSvg} {...props} />
);
export const HyperDetectIcon = (props: Partial<CustomIconComponentProps>) => (
  // @ts-ignore
  <Icon component={HyperDetectIconSvg} {...props} />
);
export const DataProtectIcon = (props: Partial<CustomIconComponentProps>) => (
  // @ts-ignore
  <Icon component={DataProtectIconSvg} {...props} />
);
export const ExploitIcon = (props: Partial<CustomIconComponentProps>) => (
  // @ts-ignore
  <Icon component={ExploitIconSvg} {...props} />
);
export const AdvancedThreatIcon = (
  props: Partial<CustomIconComponentProps>,
) => (
  // @ts-ignore
  <Icon component={AdvancedThreatIconSvg} {...props} />
);
export const AlarmIcon = (props: Partial<CustomIconComponentProps>) => (
  // @ts-ignore
  <Icon component={AlarmIconSvg} {...props} />
);
export const CveIcon = (props: Partial<CustomIconComponentProps>) => (
  // @ts-ignore
  <Icon component={CveIconSvg} {...props} />
);

export const StarOutlinedIcon = (props: Partial<CustomIconComponentProps>) => (
  // @ts-ignore
  <Icon component={StarOutlinedSvg} {...props} />
);

export const StarSolidIcon = (props: Partial<CustomIconComponentProps>) => (
  // @ts-ignore
  <Icon component={StarSolidSvg} {...props} />
);
