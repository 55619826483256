import React, { useContext } from 'react';
import { Tabs, TabsProps } from 'antd';

import DocumentsContext from '@totem/components/documents/report/documentsContext';
import DocumentsTable from '@totem/components/documents/report/documentsTable';
import DocumentUploadButton from '@totem/components/documents/report/documentUploadButton';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import TabTitle from '@totem/components/TabTitle';

const styles = {
  totals: {
    fontSize: '16px',
    marginRight: '20px',
  },
  tabContainer: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
};

const DocumentTabs = () => {
  const { totalRecords } = useContext(DocumentsContext);

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'details',
        label: <TabTitle>Documents</TabTitle>,
        children: (
          <>
            <DocumentsTable />
          </>
        ),
      },
    ];
    return tabItems;
  };

  return (
    <Tabs
      defaultActiveKey="Report"
      tabBarExtraContent={
        <div>
          <RecordCountBadge count={totalRecords} />
          &nbsp;&nbsp;&nbsp;
          <DocumentUploadButton />
        </div>
      }
      style={styles.tabContainer}
      items={getTabItems()}
    />
  );
};

export default DocumentTabs;
