import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import {
  DocumentsFilters,
  DocumentsInput,
} from '@totem/components/documents/filters';
import { DocumentReference } from '@totem/components/documents/types';
import { Filter } from '@totem/types/common';
import { isNotNull } from '@totem/utilities/common';

export const buildDocumentFilters = (input: DocumentsInput) => {
  const paramFilters: Filter[] = [];

  if (isNotNull(input.name) && input.name !== '') {
    paramFilters.push({
      field: 'displayName',
      operator: 'cscontains',
      values: [input.name],
    });
  }

  if (isNotNull(input.tag) && input.tag !== '') {
    paramFilters.push({
      field: 'tags',
      operator: 'cscontains',
      values: [input.tag],
    });
  }

  if (isNotNull(input.regionIds) && input.regionIds.length > 0) {
    paramFilters.push({
      field: 'regionId',
      values: input.regionIds,
    });
  }
  if (isNotNull(input.buildingIds) && input.buildingIds.length > 0) {
    paramFilters.push({
      field: 'buildingId',
      values: input.buildingIds,
    });
  }
  if (isNotNull(input.controlSystemIds) && input.controlSystemIds.length > 0) {
    paramFilters.push({
      field: 'controlSystemId',
      values: input.controlSystemIds,
    });
  }
  if (isNotNull(input.objectTypes) && input.objectTypes.length > 0) {
    paramFilters.push({
      field: 'objectType',
      values: input.objectTypes,
    });
  }
  if (isNotNull(input.objectIds) && input.objectIds.length > 0) {
    paramFilters.push({
      field: 'objectId',
      values: input.objectIds,
    });
  }

  return paramFilters;
};

export const addDocumentFilters = (
  currentFilters: Filter[],
  staticFilters: DocumentsFilters,
  documentReference?: DocumentReference,
) => {
  let referenceSatisfied = false;
  if (isNotNull(documentReference)) {
    if (
      isNotNull(documentReference.objectReference) &&
      documentReference.objectReference.id !== '' &&
      documentReference.objectReference.id !== EMPTY_ID
    ) {
      currentFilters.push({
        field: 'objectId',
        values: [documentReference.objectReference.id],
      });
      referenceSatisfied = true;
    }
    if (
      isNotNull(documentReference.objectReference) &&
      documentReference.objectReference.type !== ''
    ) {
      currentFilters.push({
        field: 'objectType',
        values: [documentReference.objectReference.type],
      });
    }
    if (
      !referenceSatisfied &&
      isNotNull(documentReference.controlSystemId) &&
      documentReference.controlSystemId !== EMPTY_ID
    ) {
      currentFilters.push({
        field: 'controlSystemId',
        values: [documentReference.controlSystemId],
      });
      referenceSatisfied = true;
    }
    if (
      !referenceSatisfied &&
      isNotNull(documentReference.buildingId) &&
      documentReference.buildingId !== EMPTY_ID
    ) {
      currentFilters.push({
        field: 'buildingId',
        values: [documentReference.buildingId],
      });
      referenceSatisfied = true;
    }
    if (
      !referenceSatisfied &&
      isNotNull(documentReference.regionId) &&
      documentReference.regionId !== EMPTY_ID
    ) {
      currentFilters.push({
        field: 'regionId',
        values: [documentReference.regionId],
      });
      referenceSatisfied = true;
    }
  }

  if (isNotNull(staticFilters)) {
    if (isNotNull(staticFilters.name) && staticFilters.name !== '') {
      currentFilters.push({
        field: 'displayName',
        operator: 'cscontains',
        values: [staticFilters.name],
      });
    }

    if (isNotNull(staticFilters.tag) && staticFilters.tag !== '') {
      currentFilters.push({
        field: 'tags',
        operator: 'cscontains',
        values: [staticFilters.tag],
      });
    }

    if (
      isNotNull(staticFilters.regionIds) &&
      staticFilters.regionIds.length > 0
    ) {
      currentFilters.push({
        field: 'regionId',
        values: staticFilters.regionIds,
      });
    }

    if (
      isNotNull(staticFilters.buildingIds) &&
      staticFilters.buildingIds.length > 0
    ) {
      currentFilters.push({
        field: 'buildingId',
        values: staticFilters.buildingIds,
      });
    }

    if (
      isNotNull(staticFilters.controlSystemIds) &&
      staticFilters.controlSystemIds.length > 0
    ) {
      currentFilters.push({
        field: 'controlSystemId',
        values: staticFilters.controlSystemIds,
      });
    }

    if (
      isNotNull(staticFilters.objectTypes) &&
      staticFilters.objectTypes.length > 0
    ) {
      currentFilters.push({
        field: 'objectType',
        values: staticFilters.objectTypes,
      });
    }

    if (
      isNotNull(staticFilters.objectIds) &&
      staticFilters.objectIds.length > 0
    ) {
      currentFilters.push({
        field: 'objectId',
        values: staticFilters.objectIds,
      });
    }
  }

  return currentFilters;
};
