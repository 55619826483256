import React, { useContext, useEffect, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import Address from '@totem/components/contracts/details/Address';
import Contact from '@totem/components/contracts/details/Contact';
import ContractContext from '@totem/components/contracts/details/ContractContext';
import ContractLinesTable from '@totem/components/contracts/details/ContractLinesTable';
import { ContractBillingContainer, ContractLine } from '@totem/types/contracts';

import '../contracts.css';

const styles = {
  editIcon: {
    color: '#1890ff',
  },
};

type Props = {
  loading: boolean;
  billingContainers: ContractBillingContainer[];
  isEditMode: boolean;
  onCreate: () => void;
  onEdit: (billingContainer: ContractBillingContainer) => void;
  onDelete: (billingContainer: ContractBillingContainer) => void;
  onContractLineCreate: (billingContainerId: string) => void;
  onContractLineEdit: (
    billingContainerId: string,
    contractLine: ContractLine,
  ) => void;
  onContractLineDelete: (
    billingContainerId: string,
    contractLine: ContractLine,
  ) => void;
};

const BillingContainerTable = ({
  billingContainers,
  loading,
  isEditMode,
  onCreate,
  onEdit,
  onDelete,
  onContractLineCreate,
  onContractLineEdit,
  onContractLineDelete,
}: Props) => {
  const { input } = useContext(ContractContext);
  const [filteredBillingContainers, setFilteredBillingContainers] = useState<
    ContractBillingContainer[]
  >([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [allKeys, setAllKeys] = useState<string[]>([]);

  const allExpanded = expandedKeys.length === allKeys.length;

  useEffect(() => {
    let billingContainersFiltered: ContractBillingContainer[] = JSON.parse(
      JSON.stringify(billingContainers),
    );

    if (
      typeof input.buildingId !== 'undefined' &&
      input.buildingId !== null &&
      input.buildingId.length > 0
    ) {
      billingContainersFiltered.forEach((checkBC) => {
        if (typeof checkBC.lines !== 'undefined' && checkBC.lines !== null) {
          checkBC.lines = checkBC.lines.filter((line) => {
            return input.buildingId.includes(line.serviceAddress.id);
          });
        }
      });
    }

    if (
      typeof input.status !== 'undefined' &&
      input.status !== null &&
      input.status.length > 0
    ) {
      billingContainersFiltered.forEach((checkBC) => {
        if (typeof checkBC.lines !== 'undefined' && checkBC.lines !== null) {
          checkBC.lines = checkBC.lines.filter((line) => {
            return input.status.includes(line.status);
          });
        }
      });
    }

    if (
      typeof input.service !== 'undefined' &&
      input.service !== null &&
      input.service.length > 0
    ) {
      billingContainersFiltered.forEach((checkBC) => {
        if (typeof checkBC.lines !== 'undefined' && checkBC.lines !== null) {
          checkBC.lines = checkBC.lines.filter((line) => {
            let found = false;
            line.services.forEach((service) => {
              if (input.service.includes(service.name)) {
                found = true;
              }
            });
            return found;
          });
        }
      });
    }

    billingContainersFiltered = billingContainersFiltered.filter(
      (bc) => bc.lines.length > 0,
    );

    const sortedBillingContainers = billingContainersFiltered.sort(
      (aBC, bBC) =>
        aBC.address.addressLine1 > bBC.address.addressLine1 ? 1 : -1,
    );

    setFilteredBillingContainers(sortedBillingContainers);

    const keys = sortedBillingContainers.map((chk) => chk.id);
    setAllKeys(keys);
  }, [billingContainers, input]);

  const toggleExpandAll = () => {
    setExpandedKeys(expandedKeys.length ? [] : allKeys);
  };

  const columns: ColumnProps<ContractBillingContainer>[] = [
    {
      title: 'Address',
      dataIndex: 'address.id',
      key: 'address.id',
      render: (_, billingContainer: ContractBillingContainer) => (
        <Address address={billingContainer.address} loading={loading} />
      ),
      className: 'tableAlignTop',
    },
    {
      title: 'Contact',
      dataIndex: 'contact.id',
      key: 'contact.id',
      render: (_, billingContainer: ContractBillingContainer) => (
        <Contact contact={billingContainer.contact} loading={loading} />
      ),
      className: 'tableAlignTop',
    },
    {
      title: 'Billing Frequency',
      dataIndex: 'billingFrequency',
      key: 'billingFrequency',
      className: 'tableAlignTop',
    },
    {
      title: 'Terms',
      dataIndex: 'terms',
      key: 'terms',
      className: 'tableAlignTop',
    },
    {
      title: (
        <div styleName="rightAlign">
          {isEditMode && (
            <PlusCircleOutlined
              title="Add Billing Container"
              onClick={onCreate}
              style={styles.editIcon}
            />
          )}
        </div>
      ),
      dataIndex: 'actions',
      width: '20px',
      key: 'actions',
      render: (_, billingContainer: ContractBillingContainer) => (
        <div styleName="rightAlign">
          {isEditMode && (
            <>
              <EditOutlined
                title="Edit Billing Container"
                onClick={() => onEdit(billingContainer)}
                style={styles.editIcon}
              />
              &nbsp;
              <br />
              <DeleteOutlined
                title="Delete Billing Container"
                onClick={() => onDelete(billingContainer)}
                style={styles.editIcon}
              />
            </>
          )}
        </div>
      ),
      className: 'tableAlignTop',
    },
  ];

  return (
    <>
      <Button size="small" onClick={toggleExpandAll}>
        {expandedKeys.length ? 'Collapse All' : 'Expand All'}
      </Button>
      <Table
        showSorterTooltip
        columns={columns}
        dataSource={filteredBillingContainers}
        loading={loading}
        rowKey={(record) => record.id}
        pagination={{ pageSize: 100 }}
        expandable={{
          onExpand: (expanded, record) => {
            setExpandedKeys((prev) =>
              expanded
                ? [...prev, record.id]
                : prev.filter((key) => key !== record.id),
            );
          },
          expandedRowKeys: expandedKeys,
          expandedRowRender: (billingContainer) => (
            <ContractLinesTable
              contractLines={billingContainer.lines}
              loading={loading}
              isEditMode={isEditMode}
              onCreate={() => onContractLineCreate(billingContainer.id)}
              onEdit={(contractLine: ContractLine) => {
                onContractLineEdit(billingContainer.id, contractLine);
              }}
              onDelete={(contractLine: ContractLine) => {
                onContractLineDelete(billingContainer.id, contractLine);
              }}
            />
          ),
          defaultExpandAllRows: true,
        }}
      />
    </>
  );
};

export default BillingContainerTable;
