import { createContext } from 'react';

import { DocumentsInput } from '@totem/components/documents/filters';
import {
  DocumentReference,
  DocumentSummaryEntry,
} from '@totem/components/documents/types';

interface Context {
  loading: boolean;
  data: DocumentSummaryEntry[];
  totalRecords: number;
  action: string;
  actionItem: DocumentSummaryEntry;
  onAction?: (action: string, docEntry: DocumentSummaryEntry) => void;
  input: DocumentsInput;
  setInput: (input: DocumentsInput) => void;
  selectedDocuments: DocumentSummaryEntry[];
  setDocumentSelected: (
    docEntry: DocumentSummaryEntry,
    selected: boolean,
  ) => void;
  documentReference: DocumentReference;
}

export default createContext<Context>({
  loading: false,
  data: [],
  totalRecords: 0,
  action: '',
  actionItem: null,
  onAction: () => {},
  input: {},
  setInput: () => {},
  selectedDocuments: [],
  setDocumentSelected: () => {},
  documentReference: null,
});
