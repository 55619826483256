import React, { useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import ZoneMappingPanel from '@totem/components/administration/zoneMappings/ZoneMappingPanel';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import { SettingType } from '@totem/components/settings/constants';
import AuvikMappingPanel from '@totem/components/settings/sections/auvikMapping/AuvikMappingPanel';
import BuildingMappingPanel from '@totem/components/settings/sections/buildingMapping/BuildingMappingPanel';
import CoveDataProtectionMappingPanel from '@totem/components/settings/sections/coveDataProtectionMapping/CoveDataProtectionPanel';
import NinjaOneMappingPanel from '@totem/components/settings/sections/ninjaOneMapping/NinjaOneMappingPanel';
import { default as SapientBuildingMappingPanel } from '@totem/components/settings/sections/sapientBuildingMapping/BuildingMappingPanel';
import TabTitle from '@totem/components/TabTitle';
import authUtilities from '@totem/utilities/authUtilities';

import '../../settings/settings.css';
import SentinelOneMappingPanel from '@totem/components/settings/sections/sentinelOne/SentinelOneMappingPanel';
const Mappings = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const settingsTab = params.get('st');
  const [currentKey, setCurrentKey] = useState<any>(
    typeof settingsTab !== 'undefined' && settingsTab !== null
      ? settingsTab
      : SettingType.BUILDING_MAPPING,
  );

  const Header = (
    <div styleName="content-header">
      <div>Mappings</div>
    </div>
  );

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [];

    if (
      authUtilities.minimumRequiredRole(
        authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
      )
    ) {
      tabItems.push({
        key: SettingType.BUILDING_MAPPING,
        label: <TabTitle>Nozomi Building Mapping</TabTitle>,
        children: <BuildingMappingPanel />,
      });

      tabItems.push({
        key: SettingType.ZONE_MAPPING,
        label: <TabTitle>Nozomi Zone Mapping</TabTitle>,
        children: <ZoneMappingPanel />,
      });

      tabItems.push({
        key: SettingType.NINJA_ONE_BUILDING_MAPPING,
        label: <TabTitle>NinjaOne Building Mapping</TabTitle>,
        children: <NinjaOneMappingPanel />,
      });

      tabItems.push({
        key: SettingType.SENTINEL_ONE_BUILDING_MAPPING,
        label: <TabTitle>SentinelOne Building Mapping</TabTitle>,
        children: <SentinelOneMappingPanel />,
      });

      tabItems.push({
        key: SettingType.AUVIK_BUILDING_MAPPING,
        label: <TabTitle>Auvik Building Mapping</TabTitle>,
        children: <AuvikMappingPanel />,
      });

      tabItems.push({
        key: SettingType.COVE_DATA_PROTECTION_BUILDING_MAPPING,
        label: <TabTitle>Cove Data Protection Building Mapping</TabTitle>,
        children: <CoveDataProtectionMappingPanel />,
      });

      tabItems.push({
        key: SettingType.SAPIENT_BUILDING_MAPPING,
        label: <TabTitle>Sapient Building Mapping</TabTitle>,
        children: <SapientBuildingMappingPanel />,
      });
    }

    return tabItems;
  };

  return (
    <ContentLayout>
      <PrimaryContentPane>
        {Header}
        <div styleName="content-container">
          <Tabs
            activeKey={currentKey}
            onChange={(key) => setCurrentKey(key)}
            items={getTabItems()}
          />
        </div>
      </PrimaryContentPane>
    </ContentLayout>
  );
};

export default Mappings;
