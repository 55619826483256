import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { DeviceSoftwareEndOfSupportRecord } from '@totem/components/devices/softwareEndOfLife/summary/types';
import DeviceSoftwareEndOfSupportContext
  from '@totem/components/deviceSoftware/deviceSoftwareContext';
import { isNotNull } from '@totem/utilities/common';
import { DateStringToLocalDate } from '@totem/utilities/dateUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

type Props = {
  softwareColumnTitle?: string;
};

const ControlSystemReportDeviceTable = ({ softwareColumnTitle }: Props) => {
  const { loading, data } = useContext(DeviceSoftwareEndOfSupportContext);

  const columns: ColumnProps<DeviceSoftwareEndOfSupportRecord>[] = [
    {
      title: isNotNull(softwareColumnTitle) ? softwareColumnTitle : 'Software Package',
      key: 'softwarePackageName',
      dataIndex: 'softwarePackageName',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return (
          <Link to={`/dashboard/software/${record.softwarePackageId}`}>
            {record.softwarePackageName}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.softwarePackageName,
          compB.softwarePackageName,
        ),
    },
    {
      title: 'Publisher',
      dataIndex: 'softwarePublisher',
      key: 'softwarePublisher',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return record.softwarePublisher;
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.softwarePublisher, compB.softwarePublisher),
    },
    {
      title: 'Version',
      dataIndex: 'softwareVersion',
      key: 'softwareVersion',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return record.softwareVersion;
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.softwareVersion, compB.softwareVersion),
    },
    {
      title: 'Install Date',
      dataIndex: 'softwareInstallDate',
      key: 'softwareInstallDate',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return DateStringToLocalDate(record.softwareInstallDate);
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.softwareInstallDate,
          compB.softwareInstallDate,
        ),
    },
    {
      title: 'End-of-Support Date',
      dataIndex: 'softwareEndOfSupportDate',
      key: 'softwareEndOfSupportDate',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        let output = DateStringToLocalDate(record.softwareEndOfSupportDate);
        if (output === '-') {
          if (record.softwareEndOfSupport) {
            output = 'Support Ended';
          }
        }
        return output;
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.softwareEndOfSupportDate,
          compB.softwareEndOfSupportDate,
        ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(data) ? data : []}
      loading={loading}
      rowKey={(record) =>
        record.deviceId + record.softwarePackageId + record.softwareVersion
      }
    />
  );
}

export default ControlSystemReportDeviceTable;
