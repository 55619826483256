import React from 'react';

import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';

type SizeOptions = 'small' | 'large' | 'massive';

const styles = {
  information: {
    fontSize: fonts.fontSm,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.badges.information.background,
    color: colors.badges.information.foreground,
  },
  icon: {
    color: colors.neutral.dim,
  },
  small: {
    width: '1.75rem',
    height: '1.75rem',
  },
  large: {
    width: '3.2rem',
    height: '3.2rem',
  },
  massive: {
    width: '13rem',
    height: '13rem',
    fontSize: '7rem',
  },
};

type Props = {
  count: number;
  style?: React.CSSProperties;
  size?: SizeOptions;
  tooltip?: string;
};

const InfoBadge = ({ count, size, tooltip, style }: Props) => {
  return (
    <div
      // @ts-ignore
      style={{
        ...styles.information,
        ...(styles[size] ? styles[size] : styles.small),
        ...style,
        ...(tooltip && {
          cursor: 'pointer',
        }),
      }}
    >
      {count}
    </div>
  );
};

export default InfoBadge;
