import React, { useContext } from 'react';
import GraphDataContext from '@totem/components/common/graphDiagram/graphDataContext';
import GraphDiagram from '@totem/components/common/graphDiagram/GraphDiagram';
import { isNull } from '@totem/utilities/common';
import { Empty } from 'antd';

const GraphWrapper = () => {
  const { data } = useContext(GraphDataContext);

  if (isNull(data)) {
    return <Empty />
  }

  return <GraphDiagram graphData={data} />
};

export default GraphWrapper;
