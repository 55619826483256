import { createContext } from 'react';

import { ObjectReference } from '@totem/types/common';
import {
  TicketingConnectionInput,
  TicketingFilterOptions,
  TicketQueryResult,
  TicketQueryResults,
} from '@totem/types/ticketing';
import { DeviceFilters } from '@totem/types/devices';

interface Context {
  input: TicketingConnectionInput;
  setInput: (input: TicketingConnectionInput) => void;
  loading: boolean;
  ticketData: TicketQueryResults;
  filterOptions: TicketingFilterOptions;
  staticFilters?: DeviceFilters;
  totalRecords: number;
  onTicketSelected?: (ticketId: string, ticket: TicketQueryResult) => void;
  selectedTickets: ObjectReference[];
  clearSelectedTickets: () => void;
  selectTicket: (ticketId: string, ticketNumber: string) => void;
  deselectTicket: (ticketId: string) => void;
  refetch: () => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  ticketData: null,
  filterOptions: {
    type: [],
    priority: [],
    status: [],
    subStatus: [],
    categories: [],
    building: [],
    organization: [],
    device: [],
  },
  totalRecords: 0,
  onTicketSelected: null,
  selectedTickets: [],
  clearSelectedTickets: () => {},
  selectTicket: () => {},
  deselectTicket: () => {},
  refetch: () => {},
});
