import React, { ReactNode, useEffect, useState } from 'react';

import TenantMappingContext from '@totem/components/commissionReport/tenantMapping/tenantMappingContext';
import { TenantCommissionReport } from '@totem/components/commissionReport/tenantMapping/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNull } from '@totem/utilities/common';
import { CONTRACTS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  children?: ReactNode;
};

const TenantMappingContainer = ({ children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reportData, setReportData] = useState<TenantCommissionReport>(null);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      fetch(`${CONTRACTS_ENDPOINT}/reconciliation/missingTenantMappings`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: TenantCommissionReport) => {
          setReportData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  return (
    <TenantMappingContext.Provider
      value={{
        reportData,
        loading: isLoading,
        totalRecords: isNull(reportData)
          ? 0
          : reportData.rmm.length + reportData.backup.length,
      }}
    >
      {children}
    </TenantMappingContext.Provider>
  );
};

export default TenantMappingContainer;
