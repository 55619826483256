import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';

import EventFilterContext from '@totem/components/events/eventFilters/EventFilterContext';
import EventContext from '@totem/components/events/eventsContainer/eventContainerContext';
import {
  addEventFilters,
  buildEventFilters,
} from '@totem/components/events/eventsContainer/utilities';
import { Params } from '@totem/types/common';
import { EventReportParameters } from '@totem/types/event';
import api from '@totem/utilities/api';
import { isNotNull } from '@totem/utilities/common';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';
import fileUtilities from '@totem/utilities/fileUtilities';

type Props = {
  eventEndPoint?: string;
};

const EventExportButton = ({ eventEndPoint }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { reportInput, historyOnly } = useContext(EventContext);
  const { input, staticFilters } = useContext(EventFilterContext);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: reportInput.page,
        pageSize: reportInput.pageSize,
      },
      sort: {
        field: reportInput.sortField,
        direction: +reportInput.sortDirection,
      },
      filters: buildEventFilters(
        input,
        isNotNull(historyOnly) ? historyOnly : false,
      ),
    };

    params.filters = addEventFilters(params.filters, staticFilters);

    return params;
  };

  const exportData = async () => {
    setLoading(true);

    const params = buildParameters();
    const payload: EventReportParameters = {
      startTime: dayjs(input.startTime).toISOString(),
      endTime: dayjs(input.endTime).toISOString(),
      params,
    };

    const eventUrl =
      typeof eventEndPoint !== 'undefined' && eventEndPoint !== null
        ? eventEndPoint
        : EVENTS_ENDPOINT;

    const { data } = await api.post(`${eventUrl}/export`, payload);
    fileUtilities.downloadFile('events', data);
    setLoading(false);
  };

  return (
    <Button onClick={exportData} disabled={loading}>
      Export
    </Button>
  );
};

export default EventExportButton;
