import { createContext } from 'react';

import { TenantCommissionReport } from '@totem/components/commissionReport/tenantMapping/types';

interface Context {
  loading: boolean;
  totalRecords: number;
  reportData: TenantCommissionReport;
}

export default createContext<Context>({
  loading: false,
  totalRecords: 0,
  reportData: null,
});
