import React from 'react';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import DocumentsContainer from '@totem/components/documents/report/documentsContainer';
import DocumentTabs from '@totem/components/documents/report/documentTabs';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

const styles = {
  inset: {
    marginLeft: '20px',
    marginRight: '20px',
  },
};

const DocumentsPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <DocumentsContainer>
            <div style={styles.inset}>
              <DocumentTabs />
            </div>
          </DocumentsContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default DocumentsPage;
