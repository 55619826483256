import React, { ReactNode, useEffect, useState } from 'react';
import { RMRole } from '@totem/components/remoteAccess/temperedOverlays/types';
import { REMOTE_ACCESS_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import RoleOverlayContext from '@totem/components/remoteAccess/temperedOverlays/roleOverlayContext';
import { GraphData } from '@totem/components/common/graphDiagram/types';
import { isNotNull } from '@totem/utilities/common';
import GraphDataContext from '@totem/components/common/graphDiagram/graphDataContext';

type Props = {
  roleName: string;
  children?: ReactNode;
}

const RoleOverlayContainer = ({ roleName, children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<RMRole>(null);
  const [graphData, setGraphData] = useState<GraphData>({nodes: [], links: []});

  useEffect(() => {
    const endpoint = `${REMOTE_ACCESS_ENDPOINT}/role/overlay/${roleName}`;

    fetch(`${endpoint}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: RMRole) => {
        setData(result);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isNotNull(data) && isNotNull(data.overlays)) {
      const nodes = [];
      const links = [];

      for (let oIdx = 0; oIdx < data.overlays.length; oIdx++) {
        if (isNotNull(data.overlays[oIdx].devices)) {
          const tmpNodes =  data.overlays[oIdx].devices.map((dev) => {
            return {
              id: dev.id,
              label: dev.name,
            }
          })

          nodes.push(...tmpNodes);
        }

        if (isNotNull(data.overlays[oIdx].deviceGroups)) {
          const tmpGrpNodes =  data.overlays[oIdx].deviceGroups.map((dev) => {
            return {
              id: dev.id,
              label: dev.name,
            }
          })

          nodes.push(...tmpGrpNodes);
        }

        if ( isNotNull(data.overlays[oIdx].policies)) {
          const tmpLinks = data.overlays[oIdx].policies.map((pol) => {
            return {
              source: pol.fromId,
              target: pol.toId,
              label: '',
            }
          })

          links.push(...tmpLinks);
        }
      }

      const newGraphData: GraphData = {
        nodes,
        links,
      }
      console.log('Setting GraphData: ' + JSON.stringify(newGraphData));
      setGraphData(newGraphData);
    }
  }, [data]);
  
  return (
    <RoleOverlayContext.Provider value={{
      data: data,
      loading: isLoading,
      roleName: roleName,
    }}>
      <GraphDataContext.Provider value={{
        data: graphData,
        loading: isLoading,
      }} >
        <div>{children}</div>;
      </GraphDataContext.Provider>
    </RoleOverlayContext.Provider>
  );
};

export default RoleOverlayContainer;
