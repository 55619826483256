import { Filter } from '@totem/types/common';
import { EventFilters, EventsConnectionInput } from '@totem/types/events';
import { isNotNull } from '@totem/utilities/common';

export const sortStringAscending = (compA: string, compB: string) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};
export const sortStringDescending = (compA: string, compB: string) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const sortNumberAscending = (compA: number, compB: number) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};
export const sortNumberDescending = (compA: number, compB: number) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const buildEventFilters = (input: EventsConnectionInput, historyOnly: boolean) => {
  const paramFilters: Filter[] = [];

  if (isNotNull(input)) {
    if (
      isNotNull(input.regionId) &&
      input.regionId.length > 0
    ) {
      paramFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (
      isNotNull(input.buildingId) &&
      input.buildingId.length > 0
    ) {
      paramFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (
      isNotNull(input.controlSystemId) &&
      input.controlSystemId.length > 0
    ) {
      paramFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (
      isNotNull(input.deviceId) &&
      input.deviceId.length > 0
    ) {
      paramFilters.push({
        field: 'deviceId',
        values: input.deviceId,
      });
    }
    if (
      isNotNull(input.severity) &&
      input.severity.length > 0
    ) {
      paramFilters.push({
        field: 'severity',
        values: input.severity,
      });
    }
    if (
      isNotNull(input.sourceType) &&
      input.sourceType.length > 0
    ) {
      paramFilters.push({
        field: 'sourceType',
        values: input.sourceType,
      });
    }
    if (
      isNotNull(input.eventType) &&
      input.eventType.length > 0
    ) {
      paramFilters.push({
        field: 'eventType',
        values: input.eventType,
      });
    }
    if (
      !historyOnly &&
      isNotNull(input.status) &&
      input.status.length > 0
    ) {
      paramFilters.push({
        field: 'status',
        values: input.status,
      });
    }
    if (
      isNotNull(input.reference) &&
      input.reference.length > 0
    ) {
      paramFilters.push({
        field: 'keys',
        values: input.reference,
      });
    }
  }

  return paramFilters;
};

export const addEventFilters = (
  currentFilters: Filter[],
  input: EventFilters,
) => {
  if (isNotNull(input)) {
    if (
      isNotNull(input.regionId ) &&
      input.regionId.length > 0
    ) {
      currentFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (
      isNotNull(input.buildingId) &&
      input.buildingId.length > 0
    ) {
      currentFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (
      isNotNull(input.controlSystemId) &&
      input.controlSystemId.length > 0
    ) {
      currentFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (
      isNotNull(input.deviceId) &&
      input.deviceId.length > 0
    ) {
      currentFilters.push({
        field: 'deviceId',
        values: input.deviceId,
      });
    }
    if (
      isNotNull(input.severity) &&
      input.severity.length > 0
    ) {
      currentFilters.push({
        field: 'severity',
        values: input.severity,
      });
    }
    if (
      isNotNull(input.sourceType) &&
      input.sourceType.length > 0
    ) {
      currentFilters.push({
        field: 'sourceType',
        values: input.sourceType,
      });
    }
    if (
      isNotNull(input.eventType) &&
      input.eventType.length > 0
    ) {
      currentFilters.push({
        field: 'eventType',
        values: input.eventType,
      });
    }
    if (
      isNotNull(input.status) &&
      input.status.length > 0
    ) {
      currentFilters.push({
        field: 'status',
        values: input.status,
      });
    }
    if (
      isNotNull(input.reference) &&
      input.reference.length > 0
    ) {
      currentFilters.push({
        field: 'key',
        values: input.reference,
      });
    }
  }

  return currentFilters;
};
