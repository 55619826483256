import { createContext } from 'react';

import {
  Location,
  TenantData,
} from '@totem/components/settings/sections/ninjaOneMapping/reportTypes';

interface Context {
  data: TenantData[];
  loading: boolean;
  onAction?: (action: string, tenantRecord: TenantData) => void;
  onLocationAction?: (
    action: string,
    tenantRecord: TenantData,
    location: Location,
  ) => void;
  onActionComplete?: (refresh: boolean) => void;
}

export default createContext<Context>({
  data: [],
  loading: false,
  onAction: null,
  onLocationAction: null,
  onActionComplete: null,
});
