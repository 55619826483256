import * as R from 'ramda';

export const toTitleCase = (val: string) =>
  R.replace(
    /^./,
    R.toUpper,
  )(val)
    .split(/(?=[A-Z])/)
    .join(' ');

export default {
  toTitleCase,
};

export const uniqueNonEmptyValues = (arr: (string | null | undefined)[]): string[] => {
  return [...new Set(arr.filter((val) => val && val.trim() !== ''))];
};

export const capitalizeWords = (text: string): string =>
  text.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
