import { createContext } from 'react';
import { RMRole } from '@totem/components/remoteAccess/temperedOverlays/types';

interface Context {
  roleName: string;
  data: RMRole;
  loading: boolean;
}

export default createContext<Context>({
  roleName: "",
  loading: false,
  data: null,
});
