import React, { useContext, useEffect, useRef, useState } from 'react';
import { Graph } from "react-d3-graph";

import NetworkContext from '@totem/components/buildingDetail/network/networkContext';
import { isNotNull } from '@totem/utilities/common';

const NetworkGraph = () => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 800, height: 600 });

  const { data } = useContext(NetworkContext);

  const nodes = isNotNull(data) ? data.nodes.map((node) => {
    return {
      id: node.id,
      label: node.label,
      color: node.data.deviceId !== "" ? '#5FA052' : '#83A1CD'
    };
  }) : [];
  const links = isNotNull(data) ? data.edges.map((edge) => {
    return { source: edge.source, target: edge.target, label: edge.data.label };
  }) : [];
  const graphData = {
    nodes: nodes,
    links: links,
  };

  const myConfig = {
    directed: true,
    width: dimensions.width,
    height: dimensions.height,
    nodeHighlightBehavior: true,
    node: {
      labelProperty: "label",
      color: "lightgreen",
      size: 120,
      highlightStrokeColor: "blue",
    },
    link: {
      highlightColor: "lightblue",
      renderLabel: true,
      renderArrow: true,
      arrowSize: 5,
      arrowColor: "lightblue",
    },
    minZoom: 0.1,
    maxZoom: 8,
    initialZoom: 2,
  };

  const updateDimensions = () => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", height: "1000px" /* or any desired height */ }}
    >

      <Graph
        style={{ height: '100vh', width: '100vh' }}
        id={'graph-id'}
        data={graphData}
        config={myConfig}
      />
    </div>
  );
};

      export default NetworkGraph;
