import React, { ReactNode, useEffect, useState } from 'react';

import NinjaOneBuildingSelectModal from '@totem/components/settings/sections/ninjaOneMapping/NinjaOneBuildingSelectModal';
import NinjaOneContext from '@totem/components/settings/sections/ninjaOneMapping/NinjaOneContext';
import NinjaOneLocationBuildingSelectModal from '@totem/components/settings/sections/ninjaOneMapping/NinjaOneLocationBuildingSelectModal';
import {
  Location,
  TenantData,
} from '@totem/components/settings/sections/ninjaOneMapping/reportTypes';
import { getToken } from '@totem/utilities/accountUtilities';
import { NINJA_ONE_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
};

const NinjaOneMappingContainer = ({ children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mappingData, setMappingData] = useState<TenantData[]>([]);
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [selectedData, setSelectedData] = useState<TenantData>(null);
  const [selectedLocationData, setSelectedLocationData] =
    useState<Location>(null);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }
      const endpoint = `${NINJA_ONE_ENDPOINT}/tenants`;

      fetch(`${endpoint}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: TenantData[]) => {
          setMappingData(result);
        })
        .then(() => {
          setIsSending(false);
          setIsLoading(false);
        });

      setIsSending(true);
    }
  }, [refreshData]);

  const handleAction = (action: string, tenantRecord: TenantData) => {
    setSelectedAction(action);
    setSelectedData(tenantRecord);
  };

  const handleLocationAction = (
    action: string,
    tenantRecord: TenantData,
    location: Location,
  ) => {
    setSelectedAction(action);
    setSelectedData(tenantRecord);
    setSelectedLocationData(location);
  };

  const handleActionComplete = (doRefresh: boolean) => {
    setSelectedAction('');
    setSelectedData(null);
    if (doRefresh) {
      setRefreshData(true);
    }
  };

  return (
    <NinjaOneContext.Provider
      value={{
        data: mappingData,
        loading: isLoading,
        onAction: handleAction,
        onLocationAction: handleLocationAction,
        onActionComplete: handleActionComplete,
      }}
    >
      <div>{children}</div>
      {selectedAction === 'showMappingDialog' && selectedData !== null && (
        <NinjaOneBuildingSelectModal
          visible={selectedAction === 'showMappingDialog'}
          tenantRecord={selectedData}
        />
      )}
      {selectedAction === 'showLocationMappingDialog' &&
        selectedData !== null && (
          <NinjaOneLocationBuildingSelectModal
            visible={selectedAction === 'showLocationMappingDialog'}
            tenantRecord={selectedData}
            location={selectedLocationData}
          />
        )}
    </NinjaOneContext.Provider>
  );
};

export default NinjaOneMappingContainer;
