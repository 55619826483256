import React, { useContext, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import TicketingContext from '@totem/components/ticketing/listContainer/ticketingContainerContext';
import {
  addFilters,
  buildFilters,
} from '@totem/components/ticketing/utilities';
import { Params } from '@totem/types/common';
import api from '@totem/utilities/api';
import { TICKETS_ENDPOINT } from '@totem/utilities/endpoints';
import fileUtilities from '@totem/utilities/fileUtilities';

const TicketExportButton = () => {
  const { input, staticFilters } = useContext(TicketingContext);
  const [loading, setLoading] = useState<boolean>(false);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildFilters(input),
    };

    params.filters = addFilters(params.filters, staticFilters);

    return params;
  };

  const exportData = async () => {
    setLoading(true);

    const params = buildParameters();

    const { data } = await api.post(`${TICKETS_ENDPOINT}/export`, params);
    fileUtilities.downloadFile('tickets', data);
    setLoading(false);
  };

  return (
    <Button
      type="default"
      icon={<DownloadOutlined />}
      onClick={exportData}
      loading={loading}
    >
      Export CSV
    </Button>
  );
};

export default TicketExportButton;
