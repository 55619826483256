import {
  EventFilterInput,
  EventFilterOptions,
  EventFilters,
} from '@totem/components/events/eventFilters/types';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import { Filter } from '@totem/types/common';
import { isNotNull, toPascalCase } from '@totem/utilities/common';

export const buildEventFilters = (input: EventFilterInput, historyOnly: boolean) => {
  const paramFilters: Filter[] = [];

  if (isNotNull(input)) {
    if (isNotNull(input.regionId) && input.regionId.length > 0) {
      paramFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (isNotNull(input.buildingId) && input.buildingId.length > 0) {
      paramFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (isNotNull(input.controlSystemId) && input.controlSystemId.length > 0) {
      paramFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (isNotNull(input.deviceId) && input.deviceId.length > 0) {
      paramFilters.push({
        field: 'deviceId',
        values: input.deviceId,
      });
    }
    if (isNotNull(input.severity) && input.severity.length > 0) {
      paramFilters.push({
        field: 'severity',
        values: input.severity,
      });
    }
    if (isNotNull(input.sourceSystem) && input.sourceSystem.length > 0) {
      paramFilters.push({
        field: 'sourceSystem',
        values: input.sourceSystem,
      });
    }
    if (isNotNull(input.sourceType) && input.sourceType.length > 0) {
      paramFilters.push({
        field: 'sourceType',
        values: input.sourceType,
      });
    }
    if (isNotNull(input.eventType) && input.eventType.length > 0) {
      paramFilters.push({
        field: 'eventType',
        values: input.eventType,
      });
    }
    if (!historyOnly && isNotNull(input.status) && input.status.length > 0) {
      paramFilters.push({
        field: 'status',
        values: input.status,
      });
    }
    if (isNotNull(input.reference) && input.reference.length > 0) {
      paramFilters.push({
        field: 'keys',
        values: input.reference,
      });
    }
  }

  return paramFilters;
};

export const addEventFilters = (
  currentFilters: Filter[],
  input: EventFilters,
) => {
  if (isNotNull(input)) {
    if (isNotNull(input.regionId) && input.regionId.length > 0) {
      currentFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (isNotNull(input.buildingId) && input.buildingId.length > 0) {
      currentFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (isNotNull(input.controlSystemId) && input.controlSystemId.length > 0) {
      currentFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (isNotNull(input.deviceId) && input.deviceId.length > 0) {
      currentFilters.push({
        field: 'deviceId',
        values: input.deviceId,
      });
    }
    if (isNotNull(input.severity) && input.severity.length > 0) {
      currentFilters.push({
        field: 'severity',
        values: input.severity,
      });
    }
    if (isNotNull(input.sourceSystem) && input.sourceSystem.length > 0) {
      currentFilters.push({
        field: 'sourceSystem',
        values: input.sourceSystem,
      });
    }
    if (isNotNull(input.sourceType) && input.sourceType.length > 0) {
      currentFilters.push({
        field: 'sourceType',
        values: input.sourceType,
      });
    }
    if (isNotNull(input.eventType) && input.eventType.length > 0) {
      currentFilters.push({
        field: 'eventType',
        values: input.eventType,
      });
    }
    if (isNotNull(input.status) && input.status.length > 0) {
      currentFilters.push({
        field: 'status',
        values: input.status,
      });
    }
    if (isNotNull(input.reference) && input.reference.length > 0) {
      currentFilters.push({
        field: 'key',
        values: input.reference,
      });
    }
  }

  return currentFilters;
};

export const getSeverityFilter = () => {
  return [
    {
      text: 'Critical',
      value: '5',
    },
    {
      text: 'Major',
      value: '4',
    },
    {
      text: 'Minor',
      value: '3',
    },
    {
      text: 'Warning',
      value: '2',
    },
    {
      text: 'Information',
      value: '1',
    },
    {
      text: 'OK',
      value: '0',
    },
    {
      text: 'Unknown',
      value: '-1',
    },
  ];
};

export const getDeviceFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.device
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map(dev => {
      return {
        text: dev.name,
        value: dev.value,
      };
    });
};

export const getBuildingFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.building
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map(dev => {
      return {
        text: dev.name,
        value: dev.value,
      };
    });
};

export const getStatusFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.status
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map(sts => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};

export const getSourceTypeFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.sourceType
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map(sts => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};

export const getEventTypeFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.eventType
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map(sts => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};
