import React from 'react';

import SentinelOneMappingContainer from '@totem/components/settings/sections/sentinelOne/SentinelOneMappingContainer';
import SentinelOneMappingTable from '@totem/components/settings/sections/sentinelOne/SentinelOneMappingTable';

import '../../settings.css';
const SentinelOneMappingPanel = () => {
  return (
    <div styleName="container">
      <div styleName="header-container">
        <div styleName="header">Building Mapping</div>
      </div>
      <SentinelOneMappingContainer>
        <SentinelOneMappingTable />
      </SentinelOneMappingContainer>
    </div>
  );
};

export default SentinelOneMappingPanel;
