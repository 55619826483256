import React, { useState } from 'react';
import { Flex, Select, Tabs, TabsProps } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import EventFilterContainer from '@totem/components/events/eventFilters/EventFilterContainer';
import EventFilters from '@totem/components/events/eventFilters/EventFilters';
import EventContainerTable from '@totem/components/events/eventsContainer/EventContainerTable';
import EventEmbeddedContainer from '@totem/components/events/eventsContainer/EventEmbeddedContainer';
import EventExportButton from '@totem/components/events/eventsContainer/EventExportButton';
import EventGraphsContainer from '@totem/components/events/graphs/EventGraphsContainer';
import EventGraphsLayout from '@totem/components/events/graphs/EventGraphsLayout';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import TabTitle from '@totem/components/TabTitle';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';

const styles = {
  totals: {
    fontSize: '16px',
  },
  tabContainer: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
};

const EventsPage = () => {
  const [section, setSection] = React.useState<string>('Active');
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalRecordsCleared, setTotalRecordsCleared] = useState<number>(0);

  const tabItems: TabsProps['items'] = [
    {
      key: 'summary',
      label: <TabTitle>Summary</TabTitle>,
      children: (
        <EventGraphsContainer>
          <EventGraphsLayout />
        </EventGraphsContainer>
      ),
    },
    {
      key: 'details',
      label: <TabTitle>Details</TabTitle>,
      children: <EventContainerTable />,
    },
  ];

  const tabItemsHistory: TabsProps['items'] = [
    {
      key: 'summary',
      label: <TabTitle>Summary</TabTitle>,
      children: (
        <EventGraphsContainer
          eventEndPoint={`${EVENTS_ENDPOINT}/cleared/graphData`}
        >
          <EventGraphsLayout />
        </EventGraphsContainer>
      ),
    },
    {
      key: 'details',
      label: <TabTitle>Details</TabTitle>,
      children: <EventContainerTable />,
    },
  ];

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <FiltersContainer>
            <FiltersTitle>
              <Flex justify={'space-between'} align={'center'}>
                <div>Event Management - {section}</div>
                <div style={{ paddingRight: '2rem' }}>
                  <Select
                    value={section}
                    options={[
                      { value: 'Active', label: 'Active' },
                      { value: 'History', label: 'History' },
                    ]}
                    onChange={(newValue) => setSection(newValue)}
                  />
                </div>
              </Flex>
            </FiltersTitle>
          </FiltersContainer>
          {section === 'Active' && (
            <EventFilterContainer>
              <EventEmbeddedContainer onRecordTotalChanged={setTotalRecords}>
                <EventFilters />
                <Tabs
                  defaultActiveKey="summary"
                  tabBarExtraContent={
                    <>
                      <EventExportButton />
                      {'  '}
                      <RecordCountBadge count={totalRecords} />
                    </>
                  }
                  style={styles.tabContainer}
                  items={tabItems}
                />
              </EventEmbeddedContainer>
            </EventFilterContainer>
          )}
          {section === 'History' && (
            <EventFilterContainer
              staticFilters={{ status: ['CLEARED'] }}
              historyOnly
            >
              <EventEmbeddedContainer
                eventEndPoint={`${EVENTS_ENDPOINT}/cleared`}
                onRecordTotalChanged={setTotalRecordsCleared}
              >
                <EventFilters />
                <Tabs
                  defaultActiveKey="summary"
                  tabBarExtraContent={
                    <>
                      <EventExportButton
                        eventEndPoint={`${EVENTS_ENDPOINT}/cleared`}
                      />
                      {'  '}
                      <RecordCountBadge count={totalRecordsCleared} />
                    </>
                  }
                  style={styles.tabContainer}
                  items={tabItemsHistory}
                />
              </EventEmbeddedContainer>
            </EventFilterContainer>
          )}
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default EventsPage;
