import React from 'react';

import { Device } from '@totem/components/controlSystemReport/dataTypes';
import ControlSystemReportDeviceTable from '@totem/components/deviceSoftware/controlSystemReportDeviceTable';
import DeviceSoftwareEndOsSupportReportContainer from '@totem/components/deviceSoftware/deviceSoftwareContainer';

type Props = {
  device: Device;
}

const ExpandedDeviceDetails = ({ device }: Props) => {
  return (
    <DeviceSoftwareEndOsSupportReportContainer
      deviceId={[device.id]}
      tags={['Control System Software', 'Operating System']}
    >
      <ControlSystemReportDeviceTable
        softwareColumnTitle={'Control System Software'}
      />
    </DeviceSoftwareEndOsSupportReportContainer>
  );
};

export default ExpandedDeviceDetails;
