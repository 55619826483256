import React from 'react';
import { useParams } from 'react-router-dom';

import BuildingDetailPageTitle from '@totem/components/buildingDetail/BuildingDetailPageTitle';
import BuildingContainer from '@totem/components/buildingDetail/container/BuildingContainer';
import BuildingTabs from '@totem/components/buildingDetail/container/BuildingTabs';
import ControlSystemReportContainer from '@totem/components/controlSystemReport/ControlSystemReportContainer';

import ContentLayout from '../ContentLayout';

import Sidebar from './sidebar/Sidebar';

import './buildingDetail.css';

const BuildingDetail = () => {
  const { id } = useParams();

  return (
    <BuildingContainer id={id} onDataRefreshRequested={() => {}}>
      <ContentLayout
        pageTitle={<BuildingDetailPageTitle />}
        pageSubtitle={<BuildingDetailPageTitle />}
        contentStyle={{ padding: 0 }}
      >
        <div styleName="content">
          <div styleName="content-container">
            <Sidebar />
            <div styleName="main-pane">
              <ControlSystemReportContainer
                staticFilters={{ buildingId: [id] }}
              >
                <BuildingTabs />
              </ControlSystemReportContainer>
            </div>
          </div>
        </div>
      </ContentLayout>
    </BuildingContainer>
  );
};

export default BuildingDetail;
