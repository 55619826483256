import React, { ReactNode, useEffect, useState } from 'react';

import NetworkContext from '@totem/components/buildingDetail/network/networkContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { GraphData } from '@totem/components/buildingDetail/network/types';

type Props = {
  buildingId: string;
  children?: ReactNode;
};

const NetworkContainer = ({ buildingId, children }: Props) => {
  const [data, setData] = useState<GraphData>(null);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);

      fetch(`${DEVICES_ENDPOINT}/network/chart/${buildingId}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: GraphData) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  return (
    <NetworkContext.Provider
      value={{
        data,
        isLoading,
        requestRefresh: () => setRefreshData(true),
      }}
    >
      <div>{children}</div>
    </NetworkContext.Provider>
  );
};

export default NetworkContainer;
