import React, { useCallback, useContext, useState } from 'react';
import { Button, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import SentinelOneContext from '@totem/components/settings/sections/sentinelOne/SentinelOneContext';
import { TenantData } from '@totem/components/settings/sections/sentinelOne/reportTypes';
import authUtilities from '@totem/utilities/authUtilities';
import UserProfileContext from '@totem/components/UserProfileContext';
import IBDIcon from '@totem/styles/Icon';
import { APITenant } from '@totem/components/settings/sections/auvikMapping/types';
import { RequestSentinelOneTenantUpdate } from '@totem/components/settings/sections/sentinelOne/types';
import { SENTINEL_ONE_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { emptyId } from '@totem/utilities/userUtilities';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};
const SentinelOneMappingTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { data, loading, onAction } = useContext(SentinelOneContext);
  const [isSending, setIsSending] = useState(false);

  const sendUpdate = useCallback(
    async (
      orgId: string,
      tenantData: TenantData,
    ) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const updatedTenant: APITenant = {
        organizationId: orgId,
        regionId: emptyId,
        buildingId: emptyId,
        tenantId: tenantData.tenant.tenantId,
        tenantDomainPrefix: '',
        tenantName: tenantData.tenant.tenantName,
        syncTimes: [],
      };

      const payload: RequestSentinelOneTenantUpdate = {
        apiConfigID: tenantData.id,
        tenant: updatedTenant,
      };

      fetch(`${SENTINEL_ONE_ENDPOINT}/tenants`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status < 400) {
            onAction('refresh', null);
          } else {
            notification.error({
              message: 'Error mapping org!',
              description: 'An error occurred while updating org mapping.',
              duration: 0,
            });
            onAction('refresh', null);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const onMapOrganization = (selected: TenantData) => {
    sendUpdate(userProfile.organization.id, selected);
  }

  const onUnMapOrganization = (selected: TenantData) => {
    sendUpdate(emptyId, selected);
  }

  const columns: ColumnProps<TenantData>[] = [
    {
      title: 'Name',
      dataIndex: 'tenant.tenantName',
      key: 'tenant.tenantName',
      sorter: (compA, compB) =>
        sortStringAscending(compA.tenant.tenantName, compB.tenant.tenantName),
      render: (text: string, item: TenantData) => {
        return item.tenant.tenantName;
      },
    },
    {
      title: 'Description',
      dataIndex: 'tenant.description',
      key: 'tenant.description',
      sorter: (compA, compB) =>
        sortStringAscending(compA.tenant.description, compB.tenant.description),
      render: (text: string, item: TenantData) => {
        return item.tenant.description;
      },
    },
    {
      title: 'Building',
      dataIndex: 'building.id',
      key: 'building.id',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.building.name,
          compB.building.name,
        ),
      render: (text: string, item: TenantData) => {
        return typeof item.building !== 'undefined' &&
        item.building !== null
          ? item.building.name
          : '';
      },
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'action',
      render: (_, item: TenantData) => (
        <div className="center-table-cell">
          {authUtilities.minimumRequiredRole(
            authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
          ) && (
            <>
              {userProfile.organization.id === item.tenant.organizationId && (
                <Tooltip title="Update Building Mapping" placement="top">
                  <Button
                    icon={<IBDIcon name="StarSolid" />}
                    shape="circle"
                    onClick={(): void => onUnMapOrganization(item)}
                    style={styles.iconButton}
                  />
                </Tooltip>
              )}
              {userProfile.organization.id !== item.tenant.organizationId && (
                <Tooltip title="Update Building Mapping" placement="top">
                  <Button
                    icon={<IBDIcon name="StarOutlined" />}
                    shape="circle"
                    onClick={(): void => onMapOrganization(item)}
                    style={styles.iconButton}
                  />
                </Tooltip>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={typeof data !== 'undefined' && data !== null ? data : []}
      loading={loading}
      rowKey={(record) => record.tenant.tenantId}
      pagination={{
        showSizeChanger: true,
      }}
    />
  );
};

export default SentinelOneMappingTable;
