import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CloudDownloadOutlined, DeleteOutlined, LockOutlined } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import DocumentsContext from '@totem/components/documents/report/documentsContext';
import { DocumentSummaryEntry } from '@totem/components/documents/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { formatDate, isNotNull } from '@totem/utilities/common';
import { DOCUMENTS_ENDPOINT } from '@totem/utilities/endpoints';
import {
  sortNumberAscending,
  sortStringAscending,
} from '@totem/utilities/tableUtilities';
import UserProfileContext from '@totem/components/UserProfileContext';
import { isMemberOfAny } from '@totem/utilities/userUtilities';

const DocumentsTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { data, input, setInput, loading, totalRecords, onAction } =
    useContext(DocumentsContext);

  const handleDownload = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  const columns: ColumnProps<DocumentSummaryEntry>[] = [
    {
      title: '',
      dataIndex: 'document.id',
      key: 'document.id',
      render: (_, record: DocumentSummaryEntry) => {
        const hasPermissions = record.document.securityGroups.length === 0 || isMemberOfAny(userProfile, record.document.securityGroups, {
          regionId: record.region.id,
          buildingId: record.building.id,
            controlSystemId: record.controlSystem.id,
        })

        if (!hasPermissions) {
          return <LockOutlined style={{ color: 'red' }} />;
        }

        return (
            <CloudDownloadOutlined onClick={() => handleDownload(`${DOCUMENTS_ENDPOINT}/${record.document.id}/${record.document.version}`, record.document.filename)} />
        )
      },
    },
    {
      title: 'Name',
      dataIndex: 'document.displayName',
      key: 'document.displayName',
      render: (_, record: DocumentSummaryEntry) => {
        const hasPermissions = record.document.securityGroups.length === 0 || isMemberOfAny(userProfile, record.document.securityGroups, {
          regionId: record.region.id,
          buildingId: record.building.id,
          controlSystemId: record.controlSystem.id,
        })
        if (!hasPermissions) {
          return <span>{record.document.displayName}</span>;
        }
        return (
          <a href={"javascript:void(0)"} onClick={() => handleDownload(`${DOCUMENTS_ENDPOINT}/${record.document.id}/${record.document.version}`, record.document.filename)}>{record.document.displayName}</a>
        );
      },
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.document.displayName,
          compB.document.displayName,
        ),
    },
    {
      title: 'Version',
      dataIndex: 'document.version',
      key: 'document.version',
      render: (_, record: DocumentSummaryEntry) => (
        <span>{record.document.version}</span>
      ),
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortNumberAscending(compA.document.version, compB.document.version),
    },
    {
      title: 'Size',
      dataIndex: 'document.size',
      key: 'document.size',
      render: (_, record: DocumentSummaryEntry) => (
        <span>{record.document.size}</span>
      ),
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortNumberAscending(compA.document.size, compB.document.size),
    },
    {
      title: 'Ref Type',
      dataIndex: 'document.objectType',
      key: 'document.objectType',
      render: (_, record: DocumentSummaryEntry) => (
        <span>{record.document.objectType}</span>
      ),
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.document.objectType,
          compB.document.objectType,
        ),
    },
    {
      title: 'Ref Name',
      dataIndex: 'document.objectDisplay',
      key: 'document.objectDisplay',
      render: (_, record: DocumentSummaryEntry) => {
        switch (record.document.objectType) {
          case 'Device':
            return (
              <Link to={`/dashboard/devices/${record.document.objectId}`}>
                {record.document.objectDisplay}
              </Link>
            );
          case 'Maintenance Window':
            return (
              <Link
                to={`/dashboard/maintenanceWindows/${record.document.objectId}`}
              >
                {record.document.objectDisplay}
              </Link>
            );
          default:
            return <span>{record.document.objectDisplay}</span>;
        }
      },
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.document.objectDisplay,
          compB.document.objectDisplay,
        ),
    },
    {
      title: 'Region',
      dataIndex: 'region.name',
      key: 'region.name',
      render: (_, record: DocumentSummaryEntry) => {
        return (<span>{record.region.name}</span>);
      },
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(compA.region.name, compB.region.name),
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'building.name',
      render: (_, record: DocumentSummaryEntry) => {
        if (record.building.id !== '') {
          return (
            <Link to={`/dashboard/buildings/${record.building.id}`}>
              {record.building.name}
            </Link>
          );
        }

        return <span>{record.building.name}</span>;
      },
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(compA.building.name, compB.building.name),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystem.name',
      key: 'controlSystem.name',
      render: (_, record: DocumentSummaryEntry) => {
        if (record.controlSystem.id !== '') {
          return (
            <Link to={`/dashboard/controlsystems/${record.controlSystem.id}`}>
              {record.controlSystem.name}
            </Link>
          );
        }

        return <span>{record.controlSystem.name}</span>;
      },
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(compA.controlSystem.name, compB.controlSystem.name),
    },
    {
      title: 'Created By',
      dataIndex: 'document.createdBy',
      key: 'document.createdBy',
      render: (_, record: DocumentSummaryEntry) => (
        <span>{record.document.createdBy}</span>
      ),
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(compA.document.createdBy, compB.document.createdBy),
    },
    {
      title: 'Created At',
      dataIndex: 'document.createdBy',
      key: 'document.createdBy',
      render: (_, record: DocumentSummaryEntry) => (
        <span>{formatDate(record.document.createdAt)}</span>
      ),
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(compA.document.createdAt, compB.document.createdAt),
    },
    {
      title: 'Updated At',
      dataIndex: 'document.updatedAt',
      key: 'document.updatedAt',
      render: (_, record: DocumentSummaryEntry) => (
        <span>{formatDate(record.document.updatedAt)}</span>
      ),
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(compA.document.updatedAt, compB.document.updatedAt),
    },
    {
      title: 'Expires At',
      dataIndex: 'document.expirationDate',
      key: 'document.expirationDate',
      render: (_, record: DocumentSummaryEntry) => (
        <span>{formatDate(record.document.expirationDate)}</span>
      ),
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.document.expirationDate,
          compB.document.expirationDate,
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'document.id',
      key: 'document.id',
      render: (_, record: DocumentSummaryEntry) => {
        if (record.document.createdBy === userProfile.email || isMemberOfAny(userProfile, ["documents_admin"], {
          regionId: record.region.id,
          buildingId: record.building.id,
          controlSystemId: record.controlSystem.id,
        })) {
          return (
            <DeleteOutlined title={`Delete ${record.document.displayName}`} onClick={() => onAction('hard-delete', record)} />
          )
        }
        return <span />
      },
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<DocumentSummaryEntry>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(data) ? data : []}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={(record) => record.document.id}
    />
  );
};

export default DocumentsTable;
