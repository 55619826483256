import { GraphData } from '@totem/components/common/graphDiagram/types';
import React, { useEffect, useRef, useState } from 'react';
import { Graph } from "react-d3-graph";

type Props = {
  graphData: GraphData;
}

const GraphDiagram = ({graphData}: Props) => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 800, height: 600 });

  useEffect(() => {
    console.log('GraphData: ' + JSON.stringify(graphData));
  }, [graphData]);

  const updateDimensions = () => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const graphConfig = {
    directed: true,
    width: dimensions.width,
    height: dimensions.height,
    nodeHighlightBehavior: true,
    node: {
      labelProperty: "label",
      color: "lightgreen",
      size: 120,
      highlightStrokeColor: "blue",
    },
    link: {
      highlightColor: "lightblue",
      renderLabel: true,
      renderArrow: true,
      arrowSize: 5,
      arrowColor: "lightblue",
    },
    minZoom: 0.1,
    maxZoom: 8,
    initialZoom: 2,
  };

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", height: "1000px" /* or any desired height */ }}
    >
      <Graph
        style={{ height: '100vh', width: '100vh' }}
        id={'graph-id'}
        data={graphData}
        config={graphConfig}
      />
    </div>
  );
};

export default GraphDiagram;
