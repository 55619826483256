import React from 'react';
import {
  AlertOutlined,
  AuditOutlined,
  BarsOutlined,
  BorderOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined,
  ControlOutlined,
  EditOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  FolderViewOutlined,
  FundOutlined,
  HomeOutlined,
  IdcardOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  ProductOutlined,
  ReconciliationOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';

import {
  AdvancedThreatIcon,
  CveIcon,
  DataProtectIcon,
  ExploitIcon,
  HyperDetectIcon,
  MalwareIcon,
  NetworkAttackIcon,
  RansomwareIcon,
  StarOutlinedIcon,
  StarSolidIcon,
  StorageMalwareIcon,
} from '@totem/styles/customIcons';

type Props = {
  name: string;
  title?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};
const IBDIcon = ({ name, style, onClick, title }: Props) => {
  const commonProps = {
    style,
    onClick,
    title,
  };

  const getIcon = () => {
    switch (name) {
      case 'Portfolio':
        return <FundOutlined {...commonProps} />;
      case 'Dashboard':
        return <HomeOutlined {...commonProps} />;
      case 'Region':
        return <IdcardOutlined {...commonProps} />;
      case 'Building':
        return <EnvironmentOutlined {...commonProps} />;
      case 'Control System':
        return <ToolOutlined {...commonProps} />;
      case 'Device':
        return <ToolOutlined {...commonProps} />;
      case 'Policy Audit':
        return <AuditOutlined {...commonProps} />;
      case 'Policy':
        return <ControlOutlined {...commonProps} />;
      case 'Finding':
        return <InfoCircleOutlined {...commonProps} />;
      case 'Survey':
        return <FileTextOutlined {...commonProps} />;
      case 'Survey Template':
        return <BarsOutlined {...commonProps} />;
      case 'Event':
        return <AlertOutlined {...commonProps} />;
      case 'Asset Inventory':
        return <FolderViewOutlined {...commonProps} />;
      case 'Software':
        return <ProductOutlined {...commonProps} />;
      case 'Backups':
        return <CloudUploadOutlined {...commonProps} />;
      case 'Remote Access':
        return <IdcardOutlined {...commonProps} />;
      case 'Ticket':
        return <ReconciliationOutlined {...commonProps} />;
      case 'User':
        return <UserOutlined {...commonProps} />;
      case 'Users':
        return <TeamOutlined {...commonProps} />;
      case 'Malware':
        return <MalwareIcon {...commonProps} />;
      case 'Network Attack':
        return <NetworkAttackIcon {...commonProps} />;
      case 'Ransomware':
        return <RansomwareIcon {...commonProps} />;
      case 'Storage Malware':
        return <StorageMalwareIcon {...commonProps} />;
      case 'Hyper Detect':
        return <HyperDetectIcon {...commonProps} />;
      case 'Data Protection':
        return <DataProtectIcon {...commonProps} />;
      case 'Exploit':
        return <ExploitIcon {...commonProps} />;
      case 'Advanced Threat':
        return <AdvancedThreatIcon {...commonProps} />;
      case 'CVE':
        return <CveIcon {...commonProps} />;
      case 'Add':
        return <PlusCircleOutlined {...commonProps} />;
      case 'Edit':
        return <EditOutlined {...commonProps} />;
      case 'Delete':
        return <CloseCircleOutlined {...commonProps} />;
      case 'EndOfSupport':
        return <ThunderboltOutlined {...commonProps} />;
      case 'StarOutlined':
        return <StarOutlinedIcon {...commonProps} />;
      case 'StarSolid':
        return <StarSolidIcon {...commonProps} />;
      default:
        return <BorderOutlined {...commonProps} />;
    }
  };

  return getIcon();
};

export default IBDIcon;
