import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import TableTitle from '@totem/components/controlSystemReport/TableTitle';
import MaintenanceWindowContext from '@totem/components/maintenanceWindows/maintenanceWindowContext';
import MaintenanceWindowDetails from '@totem/components/maintenanceWindows/maintenanceWindowDetails';
import { MaintenanceWindow } from '@totem/components/maintenanceWindows/types';
import { getStatus } from '@totem/components/maintenanceWindows/utilities';
import { formatDate, isNotNull } from '@totem/utilities/common';
import {
  sortNumberAscending,
  sortStringAscending,
} from '@totem/utilities/tableUtilities';

const styles = {
  button: {
    marginRight: '4px',
  },
};

const MaintenanceWindowsTable = () => {
  const { data, loading, onAction } = useContext(MaintenanceWindowContext);

  const getData = () => {
    if (isNotNull(data)) {
      return data;
    }
    return [];
  };

  const getBuilding = (record: MaintenanceWindow) => {
    const buildingNames = record.devices.map((dev) => dev.building.name)
    const uniqueBuildings = [... new Set(buildingNames)]
    if (uniqueBuildings.length === 1) {
      return uniqueBuildings[0];
    }

    return 'Multiple Buildings';
  };

  const columns: ColumnProps<MaintenanceWindow>[] = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (_, record: MaintenanceWindow) => (
        <Link
          to={`/dashboard/maintenanceWindows/${record.id}?from=maintenance-windows`}
        >
          <EyeOutlined />
        </Link>
      ),
    },
    {
      title: 'Start Time',
      dataIndex: 'details.scheduledStartTime',
      key: 'details.scheduledStartTime',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindow) =>
        formatDate(record.details.scheduledStartTime),
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.details.scheduledStartTime,
          compB.details.scheduledStartTime,
        ),
    },
    {
      title: 'End Time',
      dataIndex: 'details.scheduledEndTime',
      key: 'details.scheduledEndTime',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindow) =>
        formatDate(record.details.scheduledEndTime),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.details.scheduledEndTime,
          compB.details.scheduledEndTime,
        ),
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'building.name',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindow) => getBuilding(record),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(getBuilding(compA), getBuilding(compB)),
    },
    {
      title: 'Status',
      dataIndex: 'details.status',
      key: 'details.status',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindow) => getStatus(record),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(getStatus(compA), getStatus(compB)),
    },
    {
      title: 'Device Count',
      key: 'devices',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindow) =>
        isNotNull(record.devices) ? record.devices.length : 0,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortNumberAscending(compA.devices.length, compB.devices.length),
    },
    {
      title: 'Reason',
      dataIndex: 'details.reason',
      key: 'details.reason',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindow) => record.details.reason,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.details.reason, compB.details.reason),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      showSorterTooltip: true,
      render: (_, record: MaintenanceWindow) => record.createdBy,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.createdBy, compB.createdBy),
    },
    {
      title: <TableTitle>Actions</TableTitle>,
      dataIndex: '',
      width: '84px',
      render: (_, record: MaintenanceWindow) => (
        <div className="center-table-cell">
          <Tooltip title="Edit" placement="top">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              style={styles.button}
              onClick={() => onAction('edit', record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      pagination={{
        showSizeChanger: true,
      }}
      expandable={{
        expandedRowRender: (record) => (
          <MaintenanceWindowDetails
            maintenanceWindow={record}
            loading={loading}
            refetch={() => onAction('refresh', null)}
          />
        ),
      }}
      rowKey={(record) => record.id}
    />
  );
};

export default MaintenanceWindowsTable;
