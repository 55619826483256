import React, { useContext, useState } from 'react';
import { Button, Tabs, TabsProps } from 'antd';

import BasePane from '@totem/components/BasePane';
import BuildingContext from '@totem/components/buildingDetail/container/BuildingContext';
import DeviceTable from '@totem/components/buildingDetail/DeviceTable';
import RemoteAccessRoles from '@totem/components/common/remoteAccess/panel/RemoteAccessRoles';
import ComponentHeader from '@totem/components/ComponentHeader';
import ComplianceSelector from '@totem/components/controlSystemDetail/ComplianceSelector';
import BuildingDetailControlSystemTable from '@totem/components/controlSystemReport/BuildingDetailControlSystemTable';
import ControlSystemCreateModal from '@totem/components/controlSystemReport/ControlSystemCreateModal';
import ReportContext from '@totem/components/controlSystemReport/ControlSystemReportContext';
import NameSearch from '@totem/components/controlSystemReport/NameSearch';
import DeviceBackupPanel from '@totem/components/deviceBackups/DeviceBackupPanel';
import DeviceEmbeddedContainer from '@totem/components/devices/devicesContainer/DeviceEmbeddedContainer';
import BuildingChartsContainer from '@totem/components/energy/BuildingChartContainer';
import BuildingKPIs from '@totem/components/energy/BuildingKPIs';
import DateRangeInput from '@totem/components/energy/DateRangeInput';
import EnergyContainer from '@totem/components/energy/EnergyContainer';
import EventFilterContainer from '@totem/components/events/eventFilters/EventFilterContainer';
import EventContainerTable from '@totem/components/events/eventsContainer/EventContainerTable';
import EventEmbeddedContainer from '@totem/components/events/eventsContainer/EventEmbeddedContainer';
import PageSection from '@totem/components/PageSection';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import SurveyOverview from '@totem/components/surveyV2/building/SurveyOverview';
import ComplianceSummaryContainer from '@totem/components/surveyV2/complianceSummary/complianceSummaryContainer';
import PolicyAuditReportContainer from '@totem/components/surveyV2/policyAuditReport/policyAuditReportContainer';
import ReportTable from '@totem/components/surveyV2/policyAuditReport/ReportTable';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getForeignKeyValue } from '@totem/utilities/common';
import {
  emptyId,
  hasAnyActiveService,
  isMemberOfAny,
} from '@totem/utilities/userUtilities';

import './../buildingDetail.css';
import { getUserRole } from '@totem/utilities/userUtilities';
import MaintenanceWindowButton from '@totem/components/devices/devicesContainer/MaintenanceWindowButton';
import DeviceExport from '@totem/components/devices/devicesContainer/DeviceExport';
import DocumentsContainer from '@totem/components/documents/report/documentsContainer';
import DocumentTabs from '@totem/components/documents/report/documentTabs';
import DocumentsPanel from '@totem/components/documents/report/documentsPanel';

const BuildingTabs = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { data, isLoading } = useContext(BuildingContext);
  const [eventTotal, setEventTotal] = useState<number>(0);
  const [deviceTotal, setDeviceTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const { totalRecords } = useContext(ReportContext);

  const { questionnaire: showSurvey, remoteAccess: showRemoteAccess } =
    userProfile.organization?.preferences?.features;
  const canCreateMaintenanceWindows = getUserRole(userProfile) >= 1;
  const userCanSeeRemoteAccess = isLoading
    ? false
    : isMemberOfAny(
        userProfile,
        [
          'remote_access_viewer',
          'remote_access_admin',
          'remote_access_configuration_admin',
        ],
        {
          regionId: data.region.id,
          buildingId: data.id,
          controlSystemId: emptyId,
        },
      );

  const userCanSeeEvents = hasAnyActiveService(userProfile, [
    'threat_management',
  ]);
  const canSeeAudits = userProfile.organization.role >= 3;

  const sapientBuildingId =
    typeof data !== 'undefined' &&
    data !== null &&
    typeof data.keys !== 'undefined' &&
    data.keys !== null
      ? getForeignKeyValue(data.keys, 'Sapient', 'BuildingID')
      : '';

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: '1',
        label: <TabTitle>Compliance</TabTitle>,
        children: (
          <PageSection>
            <ComplianceSummaryContainer buildingId={[data.id]}>
              <ComplianceSelector />
            </ComplianceSummaryContainer>
          </PageSection>
        ),
      },
      {
        key: '2',
        label: <TabTitle>Control Systems</TabTitle>,
        children: (
          <PageSection>
            <BasePane>
              <PrimaryContentPane>
                <ComponentHeader
                  title={'Control System Management'}
                  options={
                    <>
                      <Button type="primary" onClick={() => setOpen(true)}>
                        Add Control System
                      </Button>
                    </>
                  }
                />
                <div styleName="search-container">
                  <NameSearch />
                  <div styleName="table-count">
                    <RecordCountBadge count={totalRecords} />
                  </div>
                </div>
                <div styleName="table-container">
                  <BuildingDetailControlSystemTable />
                </div>
                {open && (
                  <ControlSystemCreateModal
                    open={open}
                    onClose={() => setOpen(false)}
                  />
                )}
              </PrimaryContentPane>
            </BasePane>
          </PageSection>
        ),
      },
      {
        key: 'devices',
        label: <TabTitle>Devices</TabTitle>,
        children: (
          <PageSection>
            <DeviceEmbeddedContainer
              staticFilters={{ buildingId: [data.id] }}
              onRecordTotalChanged={(total) => setDeviceTotal(total)}
            >
              <ComponentHeader
                title={'Devices'}
                options={
                  <>
                    <RecordCountBadge count={deviceTotal} />
                    {canCreateMaintenanceWindows && (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <MaintenanceWindowButton />
                      </>
                    )}
                    {canCreateMaintenanceWindows && <>&nbsp;&nbsp;&nbsp;<DeviceExport /></>}
                  </>
                }
              />
              <DeviceTable showMultiselect={canCreateMaintenanceWindows} />
            </DeviceEmbeddedContainer>
          </PageSection>
        ),
      },
    ];

    if (showSurvey) {
      tabItems.push({
        key: '3',
        label: <TabTitle>Surveys</TabTitle>,
        children: (
          <PageSection>
            <SurveyOverview buildingId={data.id} />
          </PageSection>
        ),
      });
    }

    if (canSeeAudits) {
      tabItems.push({
        key: 'audits',
        label: <TabTitle>Audits</TabTitle>,
        children: (
          <PageSection>
            <PolicyAuditReportContainer
              staticFilters={{ buildingId: [data.id] }}
            >
              <ReportTable />
            </PolicyAuditReportContainer>
          </PageSection>
        ),
      });
    }

    tabItems.push({
      key: '7',
      label: <TabTitle>System Continuity</TabTitle>,
      children: (
        <PageSection>
          <DeviceBackupPanel
            pRegionId={[]}
            pBuildingId={[data.id]}
            pControlSystemId={[]}
            pDeviceId={[]}
          />
        </PageSection>
      ),
    });

    if (userCanSeeEvents) {
      tabItems.push({
        key: '5',
        label: <TabTitle>Events</TabTitle>,
        children: (
          <PageSection>
            <EventFilterContainer
              staticFilters={{
                buildingId: [data.id],
                status: ['CREATED', 'ACKNOWLEDGED'],
              }}
            >
              <EventEmbeddedContainer
                onRecordTotalChanged={(total) => setEventTotal(total)}
              >
                <ComponentHeader
                  title={'Events'}
                  options={<RecordCountBadge count={eventTotal} />}
                />
                <EventContainerTable />
              </EventEmbeddedContainer>
            </EventFilterContainer>
          </PageSection>
        ),
      });
    }

    if (showRemoteAccess && userCanSeeRemoteAccess) {
      tabItems.push({
        key: '6',
        label: <TabTitle>Remote Access</TabTitle>,
        children: (
          <PageSection>
            <RemoteAccessRoles
              regionId={data.region.id}
              buildingId={data.id}
              buildingName={data.name}
            />
          </PageSection>
        ),
      });
    }

    if (sapientBuildingId !== '') {
      tabItems.push({
        key: 'sapient',
        label: <TabTitle>Energy</TabTitle>,
        children: (
          <PageSection>
            <EnergyContainer
              dateRangeType="Last 7 Days"
              buildingId={sapientBuildingId}
              selfRegisterApis={false}
              apis={{
                portfolioKpi: true,
                portfolioCarbonKpi: true,
                portfolioChart: true,
                portfolioEndUses: true,
                buildings: false,
              }}
            >
              <ComponentHeader
                title="Energy Overview"
                options={<DateRangeInput />}
              />
              <BuildingKPIs />
              <br />
              <br />
              <BuildingChartsContainer />
            </EnergyContainer>
          </PageSection>
        ),
      });
    }

    tabItems.push({
      key: 'documents',
      label: <TabTitle>Documents</TabTitle>,
      children: (
        <DocumentsContainer
          documentReference={{
            buildingId: data.id,
            regionId: data.region.id,
          }}
        >
          <div>
            <DocumentsPanel />
          </div>
        </DocumentsContainer>
      ),
    });

    return tabItems;
  };

  return (
    <>{!isLoading && <Tabs defaultActiveKey="1" items={getTabItems()} />}</>
  );
};

export default BuildingTabs;
