import { createContext } from 'react';

import {
  Event,
  EventQueryResults,
  EventsConnectionInput,
} from '@totem/types/events';

interface Context {
  reportInput: EventsConnectionInput;
  setReportInput: (reportInput: EventsConnectionInput) => void;
  loading: boolean;
  eventData: EventQueryResults;
  totalRecords: number;
  onAction?: (action: string, event: Event) => void;
  historyOnly: boolean;
}

export default createContext<Context>({
  reportInput: {},
  setReportInput: () => {},
  loading: false,
  eventData: null,
  totalRecords: 0,
  onAction: null,
  historyOnly: false,
});
