import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import NinjaOneContext from '@totem/components/settings/sections/ninjaOneMapping/NinjaOneContext';
import {
  Location,
  TenantData,
} from '@totem/components/settings/sections/ninjaOneMapping/reportTypes';
import authUtilities from '@totem/utilities/authUtilities';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

type Props = {
  tenant: TenantData;
  locations: Location[];
};

const NinjaOneLocationMappingTable = ({ tenant, locations }: Props) => {
  const { loading, onLocationAction } = useContext(NinjaOneContext);
  const columns: ColumnProps<Location>[] = [
    {
      title: 'Name',
      dataIndex: 'LocationName',
      key: 'LocationName',
      sorter: (compA, compB) =>
        sortStringAscending(compA.locationName, compB.locationName),
      render: (text: string, item: Location) => {
        return item.locationName;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (compA, compB) =>
        sortStringAscending(compA.description, compB.description),
      render: (text: string, item: Location) => {
        return item.description;
      },
    },
    {
      title: 'Building',
      dataIndex: 'building.id',
      key: 'building.id',
      sorter: (compA, compB) =>
        sortStringAscending(compA.building.name, compB.building.name),
      render: (text: string, item: Location) => {
        return typeof item.building !== 'undefined' && item.building !== null
          ? item.building.name
          : '';
      },
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'action',
      render: (text, item: Location) => (
        <div className="center-table-cell">
          {authUtilities.minimumRequiredRole(
            authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
          ) && (
            <Tooltip title="Update Building Mapping" placement="top">
              <Button
                icon={<EditOutlined />}
                shape="circle"
                onClick={(): void =>
                  onLocationAction('showLocationMappingDialog', tenant, item)
                }
                style={styles.iconButton}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={
        typeof locations !== 'undefined' && locations !== null ? locations : []
      }
      loading={loading}
      rowKey={(record) => record.locationId}
    />
  );
};

export default NinjaOneLocationMappingTable;
