import React, { ReactNode, useEffect, useState } from 'react';
import {
  DeviceSoftwareEndOfSupportInput,
  DeviceSoftwareEndOfSupportRecord,
} from '@totem/components/devices/softwareEndOfLife/summary/types';
import DeviceSoftwareEndOfSupportContext
  from '@totem/components/deviceSoftware/deviceSoftwareContext';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { buildParameters } from '@totem/components/devices/softwareEndOfLife/summary/utilities';
import { getToken } from '@totem/utilities/accountUtilities';

type Props = {
  regionId?: string[];
  buildingId?: string[];
  controlSystemId?: string[];
  deviceId?: string[];
  tags?: string[];
  children?: ReactNode;
  onLoaded?: (recordCount: number) => void;
};

const DeviceSoftwareEndOsSupportReportContainer = ({
  regionId,
  buildingId,
  controlSystemId,
  deviceId,
  tags,
  onLoaded,
  children,
}: Props) => {
  const [input, updateInput] = useState<DeviceSoftwareEndOfSupportInput>({
    regionId,
    buildingId,
    controlSystemId,
    deviceId,
    tags,
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<DeviceSoftwareEndOfSupportRecord[]>([]);
  const [action, setAction] = useState<string>('');

  const setInput = (updated: Partial<DeviceSoftwareEndOfSupportInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    setRefreshData(true);
  }, [regionId, buildingId, controlSystemId]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/software/endOfSupport/report`;

      const payload = buildParameters(input);

      fetch(uri, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: DeviceSoftwareEndOfSupportRecord[]) => {
          setData(result);
          if (typeof onLoaded === 'function') {
            onLoaded(result.length);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
    }
  };

  return (
    <DeviceSoftwareEndOfSupportContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </DeviceSoftwareEndOfSupportContext.Provider>
  );
};

export default DeviceSoftwareEndOsSupportReportContainer;
