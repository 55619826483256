import React, { useContext } from 'react';

import CommonSidebar from '@totem/components/common/sidebar/Sidebar';
import Contacts from '@totem/components/controlSystemDetail/container/components/Contacts';
import ControlSystemContext from '@totem/components/controlSystemDetail/container/ControlSystemContext';
import SideBarNotes from '@totem/components/media/notes/SideBarNotes';
import { isNotNull } from '@totem/utilities/common';
import { emptyId } from '@totem/utilities/userUtilities';

import ControlSystemInfo from './ControlSystemInfo';

import './controlSystemSidebar.css';

const ControlSystemSidebar = () => {
  const { data } = useContext(ControlSystemContext);

  return (
    <CommonSidebar tabLabel="Details">
      <div styleName="container">
        <ControlSystemInfo />
        <Contacts />
        <SideBarNotes
          subjectId={
            isNotNull(data) && isNotNull(data.controlSystem)
              ? data.controlSystem.id
              : emptyId
          }
          title="System Notes"
          noContentMessage="Attach a note to your control system to keep track of system specific information."
        />
      </div>
    </CommonSidebar>
  );
};

export default ControlSystemSidebar;
