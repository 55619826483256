import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import SoftwareContext from '@totem/components/devices/software/softwareContext';
import { Software } from '@totem/components/devices/software/types';
import { formatDateTimeDisplay } from '@totem/utilities/timeUtilities';

const SoftwareTable = () => {
  const { data, loading } = useContext(SoftwareContext);
  const { deviceData } = useContext(DeviceContext);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const getSoftwareDisplay = (record: Software) => {
    if (record.packageID.length > 1) {
      const linkUrl = `/dashboard/software/${record.packageID}?from=device:${deviceData.device.id}:${deviceData.device.displayName}`;
      return <Link to={linkUrl}>{record.name}</Link>;
    }
    return <span>{record.name}</span>;
  };

  const columns: ColumnProps<Software>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        compA.name.toLowerCase() === compB.name.toLowerCase() ? 0 : compA.name.toLowerCase() > compB.name.toLowerCase() ? 1 : -1,
      render: (_, softwareRecord) => getSoftwareDisplay(softwareRecord),
    },
    {
      title: 'Publisher',
      dataIndex: 'publisher',
      key: 'publisher',
      sorter: (compA, compB) =>
        compA.publisher.toLowerCase() === compB.publisher.toLowerCase()
          ? 0
          : compA.publisher.toLowerCase() > compB.publisher.toLowerCase()
            ? 1
            : -1,
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      sorter: (compA, compB) =>
        compA.version === compB.version
          ? 0
          : compA.version > compB.version
            ? 1
            : -1,
    },
    {
      title: 'Date Installed',
      dataIndex: 'dateInstalled',
      key: 'dateInstalled',
      render: (date) => formatDateTimeDisplay(date),
      sorter: (compA, compB) =>
        compA.dateInstalled === compB.dateInstalled
          ? 0
          : compA.dateInstalled > compB.dateInstalled
            ? 1
            : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record, index) => index}
    />
  );
};

export default SoftwareTable;
