import React, { useCallback, useContext, useEffect, useState } from 'react';
import { notification } from 'antd';

import AddUserToRoleModal from '@totem/components/remoteAccess/dialogs/AddUserToRoleModal';
import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';
import { RemoteAccessUser } from '@totem/components/remoteAccess/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getToken } from '@totem/utilities/accountUtilities';
import {
  REMOTE_ACCESS_ENDPOINT,
  REMOTE_ACCESS_PASSWORD_RESET,
  REMOTE_ACCESS_ROLES_ENDPOINT,
} from '@totem/utilities/endpoints';
import {
  isMemberOf,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

export interface Props {
  action: string;
  selectedRoleId: string;
  selectedUser: RemoteAccessUser;
  onActionComplete: (refresh: boolean) => void;
}

const RemoteAccessActionHandler = ({
  action,
  selectedRoleId,
  selectedUser,
  onActionComplete,
}: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { data, roleData } = useContext(RemoteAccessUserContext);
  const [isSending, setIsSending] = useState(false);
  const remoteAccessAdmin =
    typeof roleData !== 'undefined' && roleData !== null
      ? isMemberOf(userProfile, 'remote_access_admin', {
          regionId: roleData.regionId,
          buildingId: roleData.buildingId,
          controlSystemId: roleData.controlSystemId,
        })
      : isMemberOf(
          userProfile,
          'remote_access_admin',
          securityGroupCheckConstraintEmpty,
        );

  const sendUserInvite = useCallback(
    async (user: RemoteAccessUser) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const payload = {
        roleID: selectedRoleId,
        email: user.email,
      };

      fetch(`${REMOTE_ACCESS_ENDPOINT}/users/invite`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status < 400) {
            onActionComplete(true);
          } else {
            notification.error({
              message: 'Error sending user invite!',
              description:
                'An error occurred while attempting to send the user invite.',
              duration: 0,
            });
            onActionComplete(false);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const sendPasswordReset = useCallback(
    async (user: RemoteAccessUser) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const payload = {
        Email: user.email,
      };

      fetch(`${REMOTE_ACCESS_PASSWORD_RESET}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status < 400) {
            onActionComplete(true);
          } else {
            notification.error({
              message: 'Error sending password reset!',
              description:
                'An error occurred while attempting to send a password reset.',
              duration: 0,
            });
            onActionComplete(false);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const sendRemoveUserFromRole = useCallback(
    async (user: RemoteAccessUser) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const payload = {
        email: user.email,
        givenName: '',
        familyName: '',
        roleId: selectedRoleId,
        roleName:
          typeof data !== 'undefined' && data !== null ? data.orgRole : '',
      };

      fetch(`${REMOTE_ACCESS_ROLES_ENDPOINT}/${payload.roleId}/${user.email}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status < 400) {
            onActionComplete(true);
          } else {
            notification.error({
              message: 'Error removing user from role!',
              description: 'An error occurred while removing user from role.',
              duration: 0,
            });
            onActionComplete(false);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  useEffect(() => {
    if (typeof action !== 'undefined' && action !== null && action !== '') {
      // eslint-disable-next-line default-case
      switch (action) {
        case 'sendPasswordReset':
          if (selectedUser !== null) {
            sendPasswordReset(selectedUser);
          }
          break;
        case 'sendUserInvite':
          if (selectedUser !== null) {
            sendUserInvite(selectedUser);
          }
          break;
        case 'removeUser':
          if (selectedUser !== null) {
            sendRemoveUserFromRole(selectedUser);
          }
          break;
      }
    }
  }, [action, selectedUser, onActionComplete]);

  return (
    <>
      {action === 'addUser' && remoteAccessAdmin && (
        <AddUserToRoleModal
          visible={action === 'addUser' && remoteAccessAdmin}
          onActionComplete={onActionComplete}
        />
      )}
    </>
  );
};

export default RemoteAccessActionHandler;
