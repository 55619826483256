import configuration from './configuration';

const baseDomain = configuration.API_DOMAIN;

export const EMAIL_VERIFICATION_ENDPOINT = `${baseDomain}/api/v1/verify-email`;
export const BUILDINGS_ENDPOINT = `${baseDomain}/api/v1/buildings`;
export const FILES_ENDPOINT = `${baseDomain}/api/v1/files`;
export const MEDIA_ENDPOINT = `${baseDomain}/api/v1/media`;
export const REPORT_ENDPOINT = `${baseDomain}/api/v1/reports`;
export const POLICY_AUDITS_ENDPOINT = `${baseDomain}/api/v1/policyaudits`;
export const FINDINGS_ENDPOINT = `${baseDomain}/api/v1/findings`;
export const USERS_ENDPOINT = `${baseDomain}/api/v1/users`;
export const USER_RESTRICTIONS_ENDPOINT = `${baseDomain}/api/v1/organizationHierarchy`;
export const REMOTE_ACCESS_ENDPOINT = `${baseDomain}/api/v1/remoteAccess`;
export const REMOTE_ACCESS_USERS_ENDPOINT = `${baseDomain}/api/v1/remoteAccess/users`;
export const REMOTE_ACCESS_SESSIONS_ENDPOINT = `${baseDomain}/api/v1/remoteAccess/sessions`;
export const REMOTE_ACCESS_ROLES_ENDPOINT = `${baseDomain}/api/v1/remoteAccess/roles`;
export const REMOTE_ACCESS_ORG_ROLES_ENDPOINT = `${baseDomain}/api/v1/remoteAccess/orgRoles`;
export const REMOTE_ACCESS_PASSWORD_RESET = `${baseDomain}/api/v1/remoteAccess/passwordReset`;
export const REMOTE_ACCESS_AUTH_TENANTS = `${baseDomain}/api/v1/remoteAccess/authTenants`;
export const REMOTE_ACCESS_ROLES_UNASSIGNED = `${baseDomain}/api/v1/remoteAccess/unassignedRoles`;
export const REMOTE_ACCESS_CONDUCTOR_VALIDATION = `${baseDomain}/api/v1/remoteAccess/provisioning/validate_conductor`;
export const REMOTE_ACCESS_PROVISIONING_CONFIGURATION = `${baseDomain}/api/v1/remoteAccess/provisioning/configuration`;
export const REMOTE_ACCESS_PROVISIONING_MAX_ACCOUNT_INACTIVITY = `${baseDomain}/api/v1/remoteAccess/provisioning/maxAccountInactivity`;
export const REMOTE_ACCESS_CONDUCTOR_AIRWALL_GROUPS = `${baseDomain}/api/v1/remoteAccess/airwall/groups`;
export const REMOTE_ACCESS_CONDUCTOR_AIRWALL_TAGS = `${baseDomain}/api/v1/remoteAccess/airwall/tags`;
export const USERS_ELIGIBLE_GROUPS_ENDPOINT = `${baseDomain}/api/v1/users/eligibleGroups`;
export const ORGANIZATION_ENDPOINT = `${baseDomain}/api/v1/organization`;
export const ORGANIZATION_SERVICES_ENDPOINT = `${baseDomain}/api/v1/organization/services`;
export const ORGANIZATION_CONTACTS_ENDPOINT = `${baseDomain}/api/v1/organization/contacts`;
export const ORGANIZATION_HIERARCHY_ENDPOINT = `${baseDomain}/api/v1/organization/hierarchy`;
export const SERVICES_ENDPOINT = `${baseDomain}/api/v1/services`;
export const EVENTS_ENDPOINT = `${baseDomain}/api/v1/events`;
export const DEVICES_ENDPOINT = `${baseDomain}/api/v1/devices`;
export const ASSET_INVENTORY_ENDPOINT = `${baseDomain}/api/v1/assetInventory`;
export const DEVICES_PAGINATION_ENDPOINT = `${baseDomain}/api/v1/devices/report`;
export const GATEWAY_PAGINATION_ENDPOINT = `${baseDomain}/api/v1/devices/report/gateways`;
export const DEVICES_CROSS_ORG_PAGINATION_ENDPOINT = `${baseDomain}/api/v1/devices/crossorganization`;
export const GATEWAY_CROSS_ORG_PAGINATION_ENDPOINT = `${baseDomain}/api/v1/devices/crossorganization/gateways`;
export const CONFIGURATION_ENDPOINT = `${baseDomain}/api/v1/configuration`;
export const TICKET_ENDPOINT = `${baseDomain}/api/v1/ticket`;
export const TICKETS_ENDPOINT = `${baseDomain}/api/v1/tickets`;
export const CONTRACTS_ENDPOINT = `${baseDomain}/api/v1/contracts`;
export const NOZOMI_ENDPOINT = `${baseDomain}/api/v1/nozomi`;
export const AUTO_COMPLETE_ENDPOINT = `${baseDomain}/api/v1/autoComplete`;
export const CONTROL_SYSTEM_REPORT = `${baseDomain}/api/v1/controlSystems/report`;
export const CONTROL_SYSTEMS_ENDPOINT = `${baseDomain}/api/v1/controlSystems`;
export const AGREEMENTS_ENDPOINT = `${baseDomain}/api/v1/agreements`;
export const END_OF_LIFE_ENDPOINT = `${baseDomain}/api/v1/endoflife`;
export const TOKEN_HEARTBEAT_ENDPOINT = `${baseDomain}/api/v1/token/heartbeat`;
export const TOKEN_REFRESH_ENDPOINT = `${baseDomain}/api/v1/token/refresh`;
export const AUTH_PROFILE_ENDPOINT = `${baseDomain}/api/v1/authProfile`;
export const LOGIN_PROFILE_ENDPOINT = `${baseDomain}/api/v1/loginProfile`;
export const WIDGET_LAYOUT_ENDPOINT = `${baseDomain}/api/v1/widgets/layout`;
export const WIDGET_PALETTE_ENDPOINT = `${baseDomain}/api/v1/widgets/palette`;
export const WIDGET_COMPONENT_ENDPOINT = `${baseDomain}/api/v1/widgets/component`;
export const COMMISSION_CONTRACT_LINES_MISSING_DEVICES = `${baseDomain}/api/v1/contracts/reconciliation/missingDevices`;
export const CONTACT_SEARCH_ENDPOINT = `${baseDomain}/api/v1/organization/contacts/search`;
export const ENERGY_ENDPOINT = `${baseDomain}/api/v1/energy`;
export const V2_CONTROL_SYSTEMS_ENDPOINT = `${baseDomain}/api/v2/controlSystems`;
export const V2_SURVEY_ENDPOINT = `${baseDomain}/api/v2/survey`;
export const V2_REGIONS_ENDPOINT = `${baseDomain}/api/v2/regions`;
export const V2_BUILDINGS_ENDPOINT = `${baseDomain}/api/v2/buildings`;
export const AUVIK_ENDPOINT = `${baseDomain}/api/v1/auvik`;
export const NINJA_ONE_ENDPOINT = `${baseDomain}/api/v1/ninja-one`;
export const SENTINEL_ONE_ENDPOINT = `${baseDomain}/api/v1/sentinel-one`;
export const COVE_DATA_PROTECTION_ENDPOING = `${baseDomain}/api/v1/coveDataProtection`;
export const NETSUITE_PENDING_ENDPOINT = `${baseDomain}/api/v1/netSuitePendingImports`;
export const TENANTS_ENDPOINT = `${ORGANIZATION_ENDPOINT}/tenants`;
export const UI_ENDPOINT = `${baseDomain}/api/v1/ui`;
export const RULES_ENDPOINT = `${baseDomain}/api/v1/rules/`;
export const DOCUMENTS_ENDPOINT = `${baseDomain}/api/v1/documents`;
