import React, { useContext, useState } from 'react';
import {
  CloseCircleOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Upload,
  UploadProps,
} from 'antd';
import { YEAR } from 'antd/es/date-picker/generatePicker/constant';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import SecurityGroupSelectorDialog from '@totem/components/documents/dialogs/securityGroupSelectorDialog';
import DocumentsContext from '@totem/components/documents/report/documentsContext';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { ObjectReference } from '@totem/types/common';
import { EligibleSecurityGroup } from '@totem/types/user';
import { getToken, USER_ROLES } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DOCUMENTS_ENDPOINT } from '@totem/utilities/endpoints';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  iconButton: {
    margin: '0 2px',
  },
  removeIcon: {
    color: '#1890ff',
  },
};

type Props = {
  visible: boolean;
  showHierarchy?: boolean;
  onClose: (refresh: boolean) => void;
};

const DocumentUploadDialog = ({ visible, showHierarchy, onClose }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { documentReference } = useContext(DocumentsContext);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<ObjectReference>(
    isNotNull(documentReference) && isNotNull(documentReference.regionId) ? { id: documentReference.regionId, name: ''} : { id: EMPTY_ID, name: '' },
  );
  const [selectedBuilding, setSelectedBuilding] = useState<ObjectReference>(
    isNotNull(documentReference) && isNotNull(documentReference.buildingId)
      ? { id: documentReference.buildingId, name: '' }
      : { id: EMPTY_ID, name: '' },
  );
  const [selectedControlSystem, setSelectedControlSystem] =
    useState<ObjectReference>(
      isNotNull(documentReference) && isNotNull(documentReference.controlSystemId) ? { id: documentReference.controlSystemId, name: ''} : { id: EMPTY_ID, name: '' },
    );
  const defaultExpirationDate = dayjs().add(10, YEAR);
  const [selectedExpirationDate, setSelectedExpirationDate] = useState<Dayjs>(
    defaultExpirationDate,
  );
  const [selectedSecurityGroups, setSelectedSecurityGroups] = useState<
    EligibleSecurityGroup[]
  >([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<number>(0);
  const [showSecurityGroupsDialog, setShowSecurityGroupsDialog] =
    useState<boolean>(false);
  const [showTagEntry, setShowTagEntry] = useState<boolean>(false);
  const [tmpTag, setTmpTag] = useState<string>('');

  const handleFileChange: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList.map((file) => file.originFileObj as RcFile));
  };

  const isFormValid = () => {
    return true;
  };

  const handleSubmit = async () => {
    if (fileList.length === 0) {
      message.error('Please upload at least one file.');
      return;
    }

    const formData = new FormData();

    // Append form metadata fields
    formData.append('regionId', selectedRegion.id);
    formData.append('buildingId', selectedBuilding.id);
    formData.append('controlSystemId', selectedControlSystem.id);
    formData.append(
      'objectType',
      isNotNull(documentReference) && isNotNull(documentReference.objectReference)
        ? documentReference.objectReference.type
        : '',
    );
    formData.append(
      'objectId',
      isNotNull(documentReference) && isNotNull(documentReference.objectReference.id)
        ? documentReference.objectReference.id
        : '',
    );
    formData.append(
      'objectDisplay',
      isNotNull(documentReference) && isNotNull(documentReference.objectReference.name)
        ? documentReference.objectReference.name
        : '',
    );
    formData.append('displayName', '');
    formData.append('expirationDate', selectedExpirationDate.toISOString());
    formData.append(
      'securityGroups',
      selectedSecurityGroups.map((chk) => chk.name).join(','),
    );
    formData.append('tags', selectedTags.join(','));

    // Append files
    fileList.forEach((file) => {
      formData.append('file', file);
    });

    setLoading(true);
    try {
      await axios.post(`${DOCUMENTS_ENDPOINT}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success('File(s) uploaded successfully!');
      setFileList([]); // Reset file list
      onClose(true);
    } catch (error) {
      message.error('File upload failed. Please try again.');
    }
    setLoading(false);
  };

  const roleOptions = USER_ROLES.filter(
    (role) => role.value <= userProfile.organization.role,
  ).map((role) => ({
    value: role.value,
    label: role.label,
  }));

  return (
    <Modal
      open={visible}
      onCancel={() => onClose(false)}
      title={<ModalTitle>Document Upload</ModalTitle>}
      confirmLoading={false}
      width={'30%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose(false)}>Close</Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!isFormValid() && !loading}
          >
            Save
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form} onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            {/* File Upload Component */}
            <Form.Item label="Upload Files">
              <Upload
                beforeUpload={() => false} // Prevent default upload behavior
                fileList={fileList.map((file) => ({
                  uid: file.uid,
                  name: file.name,
                  status: 'done',
                }))}
                multiple
                onChange={handleFileChange}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>

            {/* Date Picker */}
            <Form.Item name="expirationDate" label="Expiration Date">
              <DatePicker
                allowClear={false}
                defaultValue={selectedExpirationDate}
                value={selectedExpirationDate}
                onChange={(time) => setSelectedExpirationDate(time)}
                format={'L LT'}
                style={{ width: '200px' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Tags&nbsp;&nbsp;
                  <Button
                    icon={<PlusCircleOutlined />}
                    onClick={() => setShowTagEntry(true)}
                    disabled={showTagEntry}
                  />{' '}
                </span>
              }
              style={styles.formItem}
            >
              {showTagEntry && (
                <Flex justify="flex-start">
                  <Input
                    value={tmpTag}
                    onChange={(evt) => setTmpTag(evt.target.value)}
                    onBeforeInput={(evt) => {
                      const nativeEvent = evt.nativeEvent as InputEvent;
                      if (nativeEvent.data && nativeEvent.data.includes(',')) {
                        evt.preventDefault(); // Prevent inserting the character
                      }
                    }}
                  />
                  <Button onClick={() => setShowTagEntry(false)}>Cancel</Button>
                  <Button
                    onClick={() => {
                      setSelectedTags((prevState) => [...prevState, tmpTag]);
                      setTmpTag('');
                      setShowTagEntry(false);
                    }}
                  >
                    Add
                  </Button>
                </Flex>
              )}
              {selectedTags.map((chk) => (
                <div>
                  <CloseCircleOutlined
                    onClick={() => {
                      setSelectedTags((prevState) =>
                        prevState.filter((tag) => tag !== chk),
                      );
                    }}
                  />
                  &nbsp;&nbsp;{chk}
                </div>
              ))}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Minimum role required to view document"
              style={styles.formItem}
            >
              <Select
                onChange={(role) => setSelectedRole(role)}
                value={selectedRole}
                options={roleOptions}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Additional Security&nbsp;&nbsp;
                  <Button
                    icon={<PlusCircleOutlined />}
                    onClick={() => setShowSecurityGroupsDialog(true)}
                  />{' '}
                </span>
              }
              style={styles.formItem}
            >
              {selectedSecurityGroups.length > 0 && (
                <div style={{ color: '#FF0000', fontSize: 'small' }}>
                  (user must be a member of one of the following groups to
                  groups to view):
                </div>
              )}
              {selectedSecurityGroups.map((chk) => (
                <div>
                  <CloseCircleOutlined
                    onClick={() => {
                      setSelectedSecurityGroups((prevState) =>
                        prevState.filter((group) => group !== chk),
                      );
                    }}
                  />
                  &nbsp;&nbsp;
                  {chk.displayName}
                </div>
              ))}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showSecurityGroupsDialog && (
        <SecurityGroupSelectorDialog
          onClose={() => setShowSecurityGroupsDialog(false)}
          onSubmit={(securityGroup) => {
            if (selectedSecurityGroups.includes(securityGroup)) {
              const updatedGroups = selectedSecurityGroups.filter(
                (chk) => chk !== securityGroup,
              );
              setSelectedSecurityGroups(updatedGroups);
            } else {
              setSelectedSecurityGroups((prevItems) => [
                ...prevItems,
                securityGroup,
              ]);
            }
            setShowSecurityGroupsDialog(false);
          }}
          visible={showSecurityGroupsDialog}
        />
      )}
    </Modal>
  );
};

export default DocumentUploadDialog;
