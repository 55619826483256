import { createContext } from 'react';
import { GraphData } from '@totem/components/common/graphDiagram/types';

interface Context {
  data: GraphData;
  loading: boolean;
}

export default createContext<Context>({
  loading: false,
  data: null,
});
