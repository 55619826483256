import React from 'react';
import { Tabs, TabsProps } from 'antd';

import DocumentsContainer from '@totem/components/documents/report/documentsContainer';
import DocumentsPanel from '@totem/components/documents/report/documentsPanel';
import ApprovalsTable from '@totem/components/maintenanceWindows/approvalsTable';
import DevicesTable from '@totem/components/maintenanceWindows/devicesTable';
import EventsTable from '@totem/components/maintenanceWindows/eventsTable';
import OverlayBypassTable from '@totem/components/maintenanceWindows/overlayBypassTable';
import { MaintenanceWindow } from '@totem/components/maintenanceWindows/types';
import PageSectionBody from '@totem/components/PageSectionBody';
import TabTitle from '@totem/components/TabTitle';
import TicketEmbeddedContainer from '@totem/components/ticketing/listContainer/TicketEmbeddedContainer';
import TicketListContainerTable from '@totem/components/ticketing/listContainer/TicketListContainerTable';
import { formatDate, isNotNull } from '@totem/utilities/common';

type Props = {
  maintenanceWindow: MaintenanceWindow;
  loading: boolean;
  refetch: () => void;
};

const MaintenanceWindowDetails = ({
  maintenanceWindow,
  loading,
  refetch,
}: Props) => {
  const ticketKeys =
    isNotNull(maintenanceWindow) && isNotNull(maintenanceWindow.keys)
      ? maintenanceWindow.keys.filter((chk) => chk.system === 'Ticketing')
      : [];

  const ticketCount = ticketKeys.length;

  const tabItems: TabsProps['items'] = [
    {
      key: 'devices',
      label: <TabTitle>Devices</TabTitle>,
      children: <DevicesTable devices={maintenanceWindow.devices} />,
    },
    ...[
      maintenanceWindow.details.requiresBypass && {
        key: 'approvals',
        label: <TabTitle>Approvals</TabTitle>,
        children: (
          <ApprovalsTable
            maintenanceWindowId={maintenanceWindow.id}
            approvals={maintenanceWindow.approvals}
            loading={loading}
            refetch={refetch}
          />
        ),
      },
    ],
    ...[
      maintenanceWindow.details.requiresBypass &&
        isNotNull(maintenanceWindow.overlayNetworkBypasses) &&
        maintenanceWindow.overlayNetworkBypasses.length > 0 && {
          key: 'overlayNetworkBypasses',
          label: <TabTitle>Overlay Network Bypasses</TabTitle>,
          children: (
            <OverlayBypassTable
              overlayNetworkBypasses={maintenanceWindow.overlayNetworkBypasses}
              loading={loading}
            />
          ),
        },
    ],
    ...[
      ticketCount > 0 && {
        key: 'tickets',
        label: <TabTitle>Tickets</TabTitle>,
        children: (
          <TicketEmbeddedContainer
            staticFilters={{ ids: ticketKeys.map((chk) => chk.key) }}
          >
            <TicketListContainerTable />
          </TicketEmbeddedContainer>
        ),
      },
    ],
    {
      key: 'events',
      label: <TabTitle>Suppressed Events</TabTitle>,
      children: <EventsTable events={maintenanceWindow.blockedEvents} />,
    },
    {
      key: 'documents',
      label: <TabTitle>Documents</TabTitle>,
      children: (
        <DocumentsContainer
          documentReference={{
            objectReference: {
              type: 'Maintenance Window',
              id: maintenanceWindow.id,
              name: `${formatDate(maintenanceWindow.details.scheduledStartTime)} - ${formatDate(maintenanceWindow.details.scheduledStartTime)}`,
              description: "",
              createdAt: "",
              createdBy: "",
            },
          }}
        >
          <div>
            <DocumentsPanel />
          </div>
        </DocumentsContainer>
      ),
    },
  ];

  return (
    <PageSectionBody>
      <Tabs defaultActiveKey="devices" items={tabItems} />
    </PageSectionBody>
  );
};

export default MaintenanceWindowDetails;
