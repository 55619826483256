import React, { useContext, useState } from 'react';
import { Button } from 'antd';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import ComponentHeader from '@totem/components/ComponentHeader';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import TicketCreateModal from '@totem/components/ticketing/create/TicketCreateModal';
import MergeTicketsButton from '@totem/components/ticketing/listContainer/MergeTicketsButton';
import TicketExportButton from '@totem/components/ticketing/listContainer/TicketExportButton';
import TicketingListContainerFilterChips from '@totem/components/ticketing/listContainer/TicketingListContainerFilterChips';
import TicketListContainer from '@totem/components/ticketing/listContainer/TicketListContainer';
import TicketListContainerTable from '@totem/components/ticketing/listContainer/TicketListContainerTable';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  getUserRole,
  isMemberOf,
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import '../ticketing.css';

const TicketsPage = () => {
  const { userProfile } = useContext(UserProfileContext);
  const [refreshRequested, setRefreshRequested] = useState<boolean>(false);
  const [showNewTicketModal, setShowNewTicketModal] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const isTicketAdmin =
    isMemberOf(
      userProfile,
      'ticket_administrator',
      securityGroupCheckConstraintEmpty,
    ) || getUserRole(userProfile) > 2;

  const userCanCreateTicket =
    isMemberOfAny(
      userProfile,
      ['ticket_creator', 'ticket_administrator'],
      securityGroupCheckConstraintEmpty,
    ) || getUserRole(userProfile) === 3;

  const handleTicketCreateClosed = (ticketCreated: boolean) => {
    setShowNewTicketModal(false);
    if (ticketCreated) {
      setRefreshRequested(true);
    }
  };

  const refreshAcknowledged = () => {
    setRefreshRequested(false);
  };

  return (
    <ContentLayout>
      <BasePane>
        <TicketListContainer
          onRecordTotalChanged={setTotalRecords}
          onDataRefreshRequested={refreshAcknowledged}
          refresh={refreshRequested}
          defaultFilters={{ status: ['OPEN', 'RESOLVED'] }}
        >
          <PrimaryContentPane>
            <ComponentHeader
              title={'Ticket Management'}
              options={
                <>
                  <RecordCountBadge count={totalRecords} />
                  {isTicketAdmin && (
                    <>
                      &nbsp;&nbsp;&nbsp;
                      <MergeTicketsButton />
                    </>
                  )}
                  <TicketExportButton />
                  {userCanCreateTicket && (
                    <>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        type="primary"
                        onClick={() => setShowNewTicketModal(true)}
                      >
                        New Ticket
                      </Button>
                    </>
                  )}
                </>
              }
            />
            <CardBody>
              <div styleName="table-chips-container">
                <TicketingListContainerFilterChips />
              </div>
              <TicketListContainerTable />
            </CardBody>
          </PrimaryContentPane>
        </TicketListContainer>
      </BasePane>
      {showNewTicketModal && (
        <TicketCreateModal
          visible={showNewTicketModal}
          onClose={handleTicketCreateClosed}
        />
      )}
    </ContentLayout>
  );
};

export default TicketsPage;
