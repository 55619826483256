import {
  DEVICE_KEY_CONNECT_WISE,
  DEVICE_KEY_NINJA_ONE,
} from '@totem/components/devices/software/utilities';
import { DeviceQueryResult } from '@totem/types/devices';
import { isNotNull } from '@totem/utilities/common';

export const hasServices = (device: DeviceQueryResult) => {
  if (
    isNotNull(device) &&
    isNotNull(device.device) &&
    isNotNull(device.device.keys)
  ) {
    for (let idx = 0; idx < device.device.keys.length; idx++) {
      const chkKey = device.device.keys[idx];
      if (chkKey.type === DEVICE_KEY_NINJA_ONE && chkKey.createdBy !== "PERMANENT DELETE") {
        return true;
      }
      if (chkKey.type === DEVICE_KEY_CONNECT_WISE && chkKey.createdBy !== "PERMANENT DELETE") {
        return true;
      }
    }
  }

  return false;
};
