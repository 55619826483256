export enum SettingType {
  PROFILE = 'profile',
  ACCOUNT = 'account',
  SERVICES = 'services',
  BUILDING_MAPPING = 'building_mapping',
  ZONE_MAPPING = 'zone_mapping',
  SAPIENT_BUILDING_MAPPING = 'sapient_building_mapping',
  AUVIK_BUILDING_MAPPING = 'auvik_building_mapping',
  NINJA_ONE_BUILDING_MAPPING = 'ninja_one_building_mapping',
  SENTINEL_ONE_BUILDING_MAPPING = 'sentinel_one_building_mapping',
  COVE_DATA_PROTECTION_BUILDING_MAPPING = 'cove_data_protection_building_mapping',
}
