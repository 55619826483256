import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { Device, EventRange } from '@totem/components/controlSystemReport/dataTypes';
import InfoBadge from '@totem/components/controlSystemReport/InfoBadge';
import EventSeverityCount from '@totem/components/events/EventSeverityCount';
import { sortNumberDescending } from '@totem/utilities/tableUtilities';
import dayjs from 'dayjs';
import { isNotNull } from '@totem/utilities/common';
import ExpandedDeviceDetails from '@totem/components/controlSystemReport/ExpandedDeviceDetails';

type Props = {
  devices: Device[];
  loading: boolean;
  eventRange: EventRange;
};
const DevicesSubTable = ({ devices, loading, eventRange }: Props) => {
  const getTicketTypeCount = (type: string, record: Device) => {
    for (let idx = 0; idx < record.tickets.length; idx++) {
      if (record.tickets[idx].type.toLowerCase() === type.toLowerCase()) {
        if (record.tickets[idx].count > 0) {
          return <InfoBadge count={record.tickets[idx].count} />;
        }
        return <span>-</span>;
      }
    }
    return <span>-</span>;
  };

  const getEventDisplay = (record: Device) => {
    if (record.events === null || record.events.length === 0) {
      return <span>-</span>;
    }

    const endTime = dayjs();
    let startTime = endTime.subtract(3, 'month');

    if (isNotNull(eventRange)) {
      if (isNotNull(eventRange.startDate)) {
        startTime = dayjs(eventRange.startDate);
      }
    }

    const timeComponent = `startTime=${startTime.valueOf()}&endTime=${endTime.valueOf()}`;

    return (
      <span>
        {record.events
          .sort((compA, compB) =>
            sortNumberDescending(compA.severity, compB.severity),
          )
          .map((evt) => (
            <Link
              to={`/dashboard/events?status=CREATED&status=ACKNOWLEDGED&deviceId=${record.id}&severity=${evt.severity}&${timeComponent}`}
              target={`${record.id}.${evt.severity}`}
            >
              <EventSeverityCount severity={evt.severity} count={evt.count} />
            </Link>
          ))}
      </span>
    );
  };

  const columns: ColumnProps<Device>[] = [
    {
      title: 'Device Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (_, record: Device) => (
        <Link to={`/dashboard/devices/${record.id}`}>{record.displayName}</Link>
      ),
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.displayName === compB.displayName
          ? 0
          : compA.displayName > compB.displayName
            ? 1
            : -1,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      render: (_, record: Device) => <span>{record.vendor}</span>,
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.vendor === compB.vendor
          ? 0
          : compA.vendor > compB.vendor
            ? 1
            : -1,
    },
    {
      title: 'Operating System',
      dataIndex: 'operatingSystem',
      key: 'operatingSystem',
      render: (_, record: Device) => <span>{record.operatingSystem}</span>,
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.operatingSystem === compB.operatingSystem
          ? 0
          : compA.operatingSystem > compB.operatingSystem
            ? 1
            : -1,
    },
    {
      title: 'Events',
      dataIndex: 'events',
      key: 'events',
      align: 'center',
      render: (_, record: Device) => {
        if (record.events !== null && record.events.length > 0) {
          return <span>{getEventDisplay(record)}</span>;
        }
        return <span>-</span>;
      },
      className: 'tableAlignTop',
    },
    {
      title: 'Incidents',
      dataIndex: 'tickets.incident.count',
      key: 'tickets.incident.count',
      align: 'center',
      render: (_, record: Device) => {
        if (record.tickets !== null) {
          return (
            <Link
              to={`/dashboard/ticketing?status=OPEN&status=RESOLVED&type=Incident&deviceId=${record.id}`}
            >
              {getTicketTypeCount('Incident', record)}
            </Link>
          );
        }
        return getTicketTypeCount('Incident', record);
      },
      className: 'tableAlignTop',
    },
  ];

  const getData = () => {
    if (typeof devices !== 'undefined' && devices !== null) {
      return devices;
    }
    return [];
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.id}
      expandable={{
        expandedRowRender: (record) => (
          <ExpandedDeviceDetails device={record} />
        ),
        defaultExpandAllRows: false,
      }}
    />
  );
};

export default DevicesSubTable;
