import { createContext } from 'react';
import { GraphData } from '@totem/components/buildingDetail/network/types';

interface Context {
  data: GraphData;
  isLoading: boolean;
  requestRefresh: () => void;
}

export default createContext<Context>({
  data: null,
  isLoading: false,
  requestRefresh: () => {},
});
