import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import Table from '@totem/components/common/table/Table';
import EventActionMenu from '@totem/components/events/EventActionMenu';
import EventFilterContext from '@totem/components/events/eventFilters/EventFilterContext';
import {
  getBuildingFilter,
  getDeviceFilter,
  getEventTypeFilter,
  getSeverityFilter,
  getSourceTypeFilter,
  getStatusFilter,
} from '@totem/components/events/eventFilters/utilities';
import EventContext from '@totem/components/events/eventsContainer/eventContainerContext';
import {
  sortNumberAscending,
  sortStringAscending,
} from '@totem/components/events/eventsContainer/utilities';
import EventSeverityIndicator from '@totem/components/events/EventSeverityIndicator';
import TableFilterDropDown from '@totem/components/TableFilterDropDown';
import { Event } from '@totem/types/events';
import { isNotNull } from '@totem/utilities/common';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';

import '../events.css';

const EventsContainerTable = () => {
  const [severityFilterVisible, setSeverityFilterVisible] =
    useState<boolean>(false);
  const { input, setInput, filterOptions } = useContext(EventFilterContext);
  const {
    reportInput,
    setReportInput,
    eventData,
    loading,
    totalRecords,
    onAction,
    historyOnly,
  } = useContext(EventContext);

  const onDoAction = (action: string, event: Event) => {
    if (typeof onAction === 'function') {
      onAction(action, event);
    }
  };

  const formatDateTIme = (dateIn: string) => {
    if (dateIn !== null) {
      if (!isNaN(Date.parse(dateIn))) {
        const dateVal = new Date(dateIn);
        return (
          <>
            {dateVal.toLocaleDateString()}
            <br />
            {dateVal.toLocaleTimeString()}
          </>
        );
      }
    }
    return <span>-</span>;
  };

  const getReferences = (event: Event) => {
    if (event.keys !== null) {
      return (
        <>
          {event.keys
            .filter(
              (key) =>
                key.system === 'Ticketing' &&
                key.instance === 'IBD' &&
                key.display !== null &&
                key.display !== '',
            )
            .map((key) => {
              return (
                <Link key={key.key} to={`/dashboard/ticket/${key.key}`}>
                  {key.display}
                </Link>
              );
            })}
        </>
      );
    }
    return <span />;
  };

  const getDeviceName = (event: Event) => {
    let deviceName = event.device.displayName;

    if (
      typeof event.controlSystem !== 'undefined' &&
      event.controlSystem !== null &&
      event.controlSystem.name !== null &&
      event.controlSystem.name !== '' &&
      event.controlSystem.name !== deviceName
    ) {
      deviceName = `${deviceName} (${event.controlSystem.name})`;
    }

    return deviceName;
  };

  const getDeviceDisplay = (event: Event) => {
    if (event.deviceId !== null && event.deviceId !== EMPTY_ID) {
      return (
        <Link to={`/dashboard/devices/${event.deviceId}`}>
          {getDeviceName(event)}
        </Link>
      );
    }
    return <span>{getDeviceName(event)}</span>;
  };

  const getBuildingDisplay = (event: Event) => {
    if (
      event.building !== null &&
      event.buildingId !== null &&
      event.buildingId !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/buildings/${event.buildingId}`}>
          {event.building.name}
        </Link>
      );
    } else if (event.building !== null) {
      return <span>{event.building.name}</span>;
    }
    return <span>-</span>;
  };

  const columns: ColumnProps<Event>[] = [
    {
      title: 'Severity',
      dataIndex: 'severity',
      key: 'severity',
      className: 'severity-column',
      width: '95px',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      render: (_, event: Event) => (
        <Link to={`/dashboard/events/${event.id}`}><EventSeverityIndicator severity={event.severity} /></Link>
      ),
      sorter: (compA, compB) =>
        sortNumberAscending(compA.severity, compB.severity),

      filtered: isNotNull(input.severity) ? input.severity.length > 0 : false,
      onFilterDropdownOpenChange: () =>
        setSeverityFilterVisible(!severityFilterVisible),
      filterDropdownOpen: severityFilterVisible,
      filterDropdown: (
        <TableFilterDropDown
          value={stringifyArray(input.severity)}
          filterDropdownOpen={severityFilterVisible}
          onCloseFilter={() => setSeverityFilterVisible(false)}
          onChange={(newOptions) =>
            setInput({ ...input, severity: newOptions })
          }
          options={getSeverityFilter()}
        />
      ),
    },
    {
      title: 'Source',
      dataIndex: 'sourceType',
      key: 'sourceType',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.sourceType, compB.sourceType),
      filterMultiple: true,
      filteredValue: stringifyArray(input.sourceType),
      filters: getSourceTypeFilter(filterOptions),
    },
    {
      title: 'Type',
      dataIndex: 'eventType',
      key: 'eventType',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.eventType, compB.eventType),
      filterMultiple: true,
      filteredValue: stringifyArray(input.eventType),
      filters: getEventTypeFilter(filterOptions),
    },
    {
      title: 'Device',
      dataIndex: 'deviceId',
      key: 'deviceId',
      render: (_, event: Event) => (
        <Popover
          content={
            <>
              {event.region !== null && event.region.name !== '' && (
                <>
                  <b>Region:</b>
                  <br />
                  {event.region.name}
                  <br />
                </>
              )}
              {event.building !== null && event.building.name !== '' && (
                <>
                  <b>Building:</b>
                  <br />
                  {event.building.name}
                  <br />
                </>
              )}
              {event.controlSystem !== null &&
                event.controlSystem.name !== '' && (
                  <>
                    <b>Control System:</b>
                    <br />
                    {event.controlSystem.name}
                    <br />
                  </>
                )}
              {(event.region === null || event.region.name === '') &&
                (event.building === null || event.building.name === '') &&
                (event.controlSystem === null ||
                  event.controlSystem.name === '') && <b>Unassigned</b>}
            </>
          }
        >
          <div>{getDeviceDisplay(event)}</div>
        </Popover>
      ),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.device.displayName, compB.device.displayName),
      filterMultiple: true,
      filteredValue: stringifyArray(input.deviceId),
      filters: getDeviceFilter(filterOptions),
    },
    {
      title: 'Building',
      dataIndex: 'buildingId',
      key: 'buildingId',
      render: (_, event: Event) => getBuildingDisplay(event),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.building.name, compB.building.name),
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters: getBuildingFilter(filterOptions),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Event Time',
      dataIndex: 'eventTime',
      key: 'eventTime',
      render: (date) => formatDateTIme(date),
      sorter: (compA, compB) =>
        sortStringAscending(compA.eventTime, compB.eventTime),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Last Occur.',
      dataIndex: 'lastOccurrence',
      key: 'lastOccurrence',
      render: (date) => formatDateTIme(date),
      sorter: (compA, compB) =>
        sortStringAscending(compA.lastOccurrence, compB.lastOccurrence),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Ref',
      dataIndex: 'ref',
      key: 'ref',
      render: (_, event: Event) => <>{getReferences(event)}</>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => sortStringAscending(compA.status, compB.status),
      render: (_, event: Event) => (
        <span>
          {event.status.charAt(0).toUpperCase() +
            event.status.toLowerCase().slice(1)}
        </span>
      ),
      filterMultiple: !historyOnly ? true : null,
      filteredValue: !historyOnly ? stringifyArray(input.status) : null,
      filters: !historyOnly ? getStatusFilter(filterOptions) : null,
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, event: Event) => (
        <div className="center-table-cell">
          <EventActionMenu
            event={event}
            onView={() => onDoAction('showEventDetails', event)}
            onAcknowledge={() => onDoAction('acknowledgeEvent', event)}
            onClear={() => onDoAction('clearEvent', event)}
            onCreateTicket={() => onDoAction('createTicket', event)}
            onShowAssociateTicket={() =>
              onDoAction('showAssociateTicketDialog', event)
            }
          />
        </div>
      ),
    },
  ];

  const getEvents = () => {
    if (eventData !== null) {
      if (eventData.events !== null) {
        return eventData.events;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<Event>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof reportInput.sortDirection !== 'undefined' &&
        reportInput.sortDirection !== null
      ) {
        sortDir = reportInput.sortDirection;
      }
    }

    let sortField = reportInput.sortField
      ? reportInput.sortField
      : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    setReportInput({
      ...reportInput,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      startTime: input.startTime,
      endTime: input.endTime,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getEvents()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: reportInput.page,
        pageSize: reportInput.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey="id"
    />
  );
};

export default EventsContainerTable;
